import Benefit1 from '@/assets/images/benefit-1.webp';
import Benefit2 from '@/assets/images/benefit-2.webp';
import Benefit3 from '@/assets/images/benefit-3.webp';
import Benefit4 from '@/assets/images/benefit-4.webp';
import { SubscriptionTier } from '@/core/constants';
import { SUBSCRIPTION_TYPE } from '@repo/api/payment';
import {
  AuthFlowVariantFlag,
  SubscriptionPaywallVariant,
  useGlobalFeatureFlagContext,
} from '@repo/common/services/features-book';
import { useGetMeQuery, useIsSubscribed } from '@repo/modules/entity/user-queries';
import { subscriptionPaywallDialog } from '@repo/modules/subscription-paywall-dialog';

import { DiscountBanner } from '@/features/discount-banner';
import { authModalAction, buySubscriptionModal } from '@/features/modals';

import { useTestGroupPaywallGifEnabled } from '@/entities/ab_test';
import { useBrandFeatures } from '@/entities/brand';
import { usePaymentAnalytic } from '@/entities/payment/hooks/use-payment-analytic';
import { PriceRadioBtn } from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';

type PaymentResultOptions = {
  onSuccess?: () => void;

  onFailed?: () => void;
};

type Options = {
  subsType?: SUBSCRIPTION_TYPE;

  analyticData?: any;
};

export const useSubscriptionPaywall = (options?: Options) => {
  const { subsType = SUBSCRIPTION_TYPE.PRO, analyticData } = options ?? {};

  const me = useGetMeQuery();

  const isSubscribed = useIsSubscribed();

  const features = useBrandFeatures();

  const { subsPaywallVariant, subsPriceVariant, authFlowVariant } = useGlobalFeatureFlagContext();

  const withAuth = AuthFlowVariantFlag.AUTH_BEFORE_ACTION === authFlowVariant;

  const isGifEnabled = useTestGroupPaywallGifEnabled(me?.data?.split_test_groups) && features?.ab_test_gif_on_paywall;

  const paymentAnalytic = usePaymentAnalytic();

  const onDefaultPaywall = (customPicture?: string, resultOptions?: PaymentResultOptions) => {
    const _variant = subsType === SUBSCRIPTION_TYPE.PRO ? SubscriptionTier.PRO : SubscriptionTier.PRO_PLUS;

    buySubscriptionModal.open({
      characterPicture: customPicture,
      subscriptionVariant: _variant,
      analyticData,
      onSuccessPayment: resultOptions?.onSuccess,
    });
    // dialog.open({
    //   key: DIALOG_KEYS.BUY_SUBSCRIPTION,
    //   component: (
    //     <BuySubscriptionModal
    //       characterPicture={customPicture}
    //       subscriptionVariant={_variant}
    //       analyticData={analyticData}
    //       onSuccessPayment={resultOptions?.onSuccess}
    //     />
    //   ),
    // });
  };

  const paywallPicture = () => {
    if (subsType === SUBSCRIPTION_TYPE.PRO) {
      return isGifEnabled ? features?.subscription_gif?.pro : features?.subscription_image?.pro;
    }
    return isGifEnabled ? features?.subscription_gif?.pro : features?.subscription_image?.pro_plus;
  };

  const onPaywall1 = (customPicture?: string, resultOptions?: PaymentResultOptions) => {
    subscriptionPaywallDialog.alternativeMonetization.open({
      characterImgSrc: customPicture ?? paywallPicture() ?? '',
      bannerSlot: features?.discount_banner ? <DiscountBanner className="rounded-[inherit]" /> : null,

      captionSlot: () => null,
      packItemSlot: ({ pack, isSelected }) => {
        return (
          <PriceRadioBtn
            period={pack.period}
            price={pack.originalPrice}
            discount={features?.subscription_discount ? features?.subscription_discount[pack.period] : pack.discount}
            currencyCode={features?.currency_code ?? CURRENCY_CODE.USD}
            subscriptionView={subsPriceVariant as any}
            isActive={isSelected}
          />
        );
      },
      benefits: [
        { key: 1, title: 'Unlock unlimited chats and messages!', icon: Benefit1 },
        { key: 2, title: 'Create your own models', icon: Benefit2 },
        { key: 3, title: 'Get 150 free coins', icon: Benefit3 },
        { key: 4, title: 'Unlock all hot content', icon: Benefit4 },
      ],
      onSuccess: (pack) => {
        if (!pack) return;
        paymentAnalytic.success({
          pack,
          type: 'subscription',
        });
        resultOptions?.onSuccess?.();
      },
      onFailed: (pack) => {
        if (!pack) return;
        paymentAnalytic.failed({
          pack,
          type: 'subscription',
        });
        resultOptions?.onFailed?.();
      },
      onCheckout: (pack) => {
        if (!pack) return;
        paymentAnalytic.startCheckout({
          pack,
          type: 'subscription',
        });
      },
    });
  };

  const onOpenPaywall = (customPicture?: string, resultOptions?: PaymentResultOptions) => {
    switch (subsPaywallVariant) {
      case SubscriptionPaywallVariant.DEFAULT:
        onDefaultPaywall(customPicture, resultOptions);
        break;
      case SubscriptionPaywallVariant.PAYWALL_1:
        onPaywall1(customPicture, resultOptions);
        break;
    }
  };

  const onOpenPaywallWithAuth = (customPicture?: string) => {
    if (withAuth && !isSubscribed && !me.data?.is_registered) {
      authModalAction.open(
        {
          onSuccess: (user) => {
            if (!user.is_subscribed) onOpenPaywall(customPicture);
          },
        },
        {
          afterClose: () => onOpenPaywall(customPicture),
        },
      );
    } else {
      onOpenPaywall(customPicture);
    }
  };

  return {
    onOpenPaywall,
    onOpenPaywallWithAuth,
  };
};
