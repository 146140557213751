import type { CreateStripePaymentDTO, CreateStripeSubscriptionResponse } from '@repo/api/payment';
import { PaymentRepository } from '@repo/api/payment';
import type { InnerUseMutationOptions } from '@repo/common/types/react-query';
import { extractCookieValue } from '@repo/common/utils/helpers';
import { useMutation } from '@tanstack/react-query';
import { CookiesDictionary } from '../../../shared/constants/cookies-dictionary';

export const useCreateStripeCreditGateway = (
  options?: InnerUseMutationOptions<CreateStripeSubscriptionResponse, unknown, CreateStripePaymentDTO>,
) => {
  const redTrackId = extractCookieValue(CookiesDictionary.RED_TRACK);
  return useMutation({
    ...options,
    mutationFn: (dto: CreateStripePaymentDTO) =>
      PaymentRepository.createStripePayment({
        ...dto,
        ...(redTrackId && { rtkclickid_store: redTrackId }),
      }),
  });
};
