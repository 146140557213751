import type { HTMLAttributes, ReactNode } from 'react';

import '../../styles.scss';
import { UiTypography } from '@repo/ui-kit/ui-typography';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLElement> {
  label: ReactNode;

  timer?: ReactNode;

  onClick: () => void;
}

export const Layout = (props: Props) => {
  const { label, timer, onClick, className, style, ...restProps } = props;

  return (
    <div
      onClick={onClick}
      className={cn(
        'bg-cover bg-right relative before:absolute  before:-z-1 before:left-0 before:top-0 before:size-full before:bg-cover before:bg-no-repeat bg-no-repeat flex justify-between py-2 px-3 items-center',
        className,
      )}
      style={style}
      {...restProps}
    >
      <div className="discount-banner-label flex items-center w-fit h-10 rounded-button-md px-3">
        <div>
          <UiTypography as="span" weight="semibold" variant="4xl" className="flex items-center">
            {label}
          </UiTypography>
        </div>
      </div>
      {timer && (
        <div className="discount-timer w-full max-w-[134px] h-10 flex items-center py-3 px-2 rounded-button-md">
          {timer}
        </div>
      )}
    </div>
  );
};
