import { MouseEventHandler, ReactNode, Ref, useMemo } from 'react';

import { DialogPlacement, DialogProps } from '../../types';
import { CloseButton } from '../close-button';
import { useValueByMedia } from '@repo/common/hooks/use-value-by-media';
import { cn, processReactNode } from '@repo/common/utils/component';
import { type HTMLMotionProps, Variants, motion } from 'framer-motion';

const motionVariants: Variants = {
  closed: {
    opacity: 0.6,
    y: 50,
    transition: {
      opacity: {
        delay: 0.2,
      },
    },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      mass: 0.5,
      stiffness: 100,
    },
  },
};

// const motionTransition: Transition = {
//   duration: 0.3,
//   ease: 'easeInOut',
// };

const motionDictionary = {
  base: {
    variants: motionVariants,
    // transition: motionTransition,
    initial: 'closed',
    animate: 'open',
    exit: 'closed',
  },
};

const placementDictionary: Record<DialogPlacement, string> = {
  bottom: 'ui-justify-end',
  top: 'ui-justify-start',
  center: 'ui-justify-center',
  left: 'ui-items-start',
  right: 'ui-items-end',
};

export interface Props extends HTMLMotionProps<'div'> {
  animationType?: keyof typeof motionDictionary;
  innerRef?: Ref<HTMLDivElement>;
  placement?: DialogProps['placement'];
  fullScreen?: DialogProps['fullScreen'];
  fullScreenGap?: DialogProps['fullScreenGap'];
  closeBtnProps?: DialogProps['closeBtnProps'];
  showCloseBtn?: boolean;
  onClose?: () => void;
  onExit?: () => void;
  renderCloseBtn?: (() => ReactNode) | ReactNode;
}

export const DialogContent = (props: Props) => {
  const {
    placement,
    fullScreen,
    fullScreenGap,
    children,
    closeBtnProps,
    className,
    innerRef,
    animationType = 'base',
    showCloseBtn,
    onClose,
    onExit,
    renderCloseBtn,
    ...restProps
  } = props;

  const {
    placement: closeBtnPlacement = 'top-right',
    className: classNameCloseBtn,
    onClick: onClickCloseBtn,
    ...restPropCloseBtn
  } = closeBtnProps || {};

  const _closeBtnPlacement = useValueByMedia(closeBtnPlacement);

  const _fullScreen = useValueByMedia(fullScreen, {
    phone: true,
  });

  const _fullScreenGap = useValueByMedia(fullScreenGap, {
    phone: _fullScreen,
  });

  const _placement = useValueByMedia(placement, {
    phone: 'bottom',
    desktop: 'center',
  });

  const onInnerClickCloseBtn: MouseEventHandler<HTMLButtonElement> = (event) => {
    onExit?.();
    if (onClickCloseBtn) {
      onClickCloseBtn(event);
      return;
    }
    onClose?.();
  };

  const motionProps = motionDictionary[animationType];

  const innerCloseBtn = useMemo(() => {
    if (!showCloseBtn) return null;

    if (renderCloseBtn !== undefined) {
      return processReactNode(renderCloseBtn);
    }

    return (
      <CloseButton
        onClick={onInnerClickCloseBtn}
        className={cn(
          'ui-absolute ui-z-10',
          {
            'ui-top-4 ui-right-4': _closeBtnPlacement === 'top-right',
            'ui-top-4 ui-left-4': _closeBtnPlacement === 'top-left',
            'ui-bottom-4 ui-right-4': _closeBtnPlacement === 'bottom-right',
            'ui-bottom-4 ui-left-4': _closeBtnPlacement === 'bottom-left',
          },
          classNameCloseBtn,
        )}
        {...restPropCloseBtn}
      />
    );
  }, [renderCloseBtn, onInnerClickCloseBtn, showCloseBtn, _closeBtnPlacement, restPropCloseBtn, classNameCloseBtn]);

  return (
    <motion.div
      className={cn(
        'ui-relative ui-flex ui-flex-col ui-justify-center ui-items-center ui-w-full ui-h-screen supports-dvh:ui-h-dvh ui-px-4',
        {
          '!ui-px-0': _fullScreen,
          'ui-pt-[clamp(0.75rem,3.15vh,1.5rem)]': _fullScreenGap,
        },
        _placement ? placementDictionary[_placement] : '',
      )}
    >
      <motion.div
        ref={innerRef}
        className={cn(
          'ui-relative ui-dialog-content ui-rounded-modal ui-shadow-dialog ui-overflow-hidden',
          {
            'ui-w-full ui-h-full ui-rounded-none': _fullScreen,
            'ui-rounded-t-modal': _fullScreenGap,
          },
          className,
        )}
        {...motionProps}
        {...restProps}
      >
        <>
          {children}
          {innerCloseBtn}
        </>
      </motion.div>
    </motion.div>
  );
};
