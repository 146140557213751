import { cn } from '@repo/common/utils/component';
import { UiButton } from '@repo/ui-kit/ui-button';
import { UiAnimatedIcon } from '@repo/ui-kit/ui-icon';
import type { GeneralCheckoutButtonProps } from './_types';

export interface Props extends Omit<GeneralCheckoutButtonProps, 'packId' | 'onSuccess' | 'onCheckout' | 'onFailed'> {
  isPending?: boolean;
}

export const BaseButton = (props: Props) => {
  const { disabled, isPending, ...restProps } = props;
  return (
    <UiButton
      disabled={isPending || disabled}
      rightElement={
        <UiAnimatedIcon
          k="spinner"
          className={cn('mod-text-2xl', {
            hidden: !isPending,
          })}
        />
      }
      variant="primary"
      type="button"
      {...restProps}
    />
  );
};
