import type { CSSProperties } from 'react';

import '../../styles.scss';
import { UiIcon } from '@repo/ui-kit/ui-icon';
import { UiTypography } from '@repo/ui-kit/ui-typography';

import { useTimer } from '@/features/discount-banner/_model';

export interface Props {
  createdAt: string;

  cycleDurationSeconds: number;
}

export const Timer = (props: Props) => {
  const { createdAt, cycleDurationSeconds } = props;
  const time = useTimer(createdAt, cycleDurationSeconds);

  return (
    <div className="inline-flex gap-2 items-center">
      <div className=" inline-flex items-center justify-center">
        <UiIcon k="clock" className="" style={{ '--stroke': 'rgb(var(--color-brand-primary))' } as CSSProperties} />
      </div>
      <UiTypography variant="2xl" weight="semibold" className="inline-flex items-center">
        <UiTypography as="span" weight="semibold">
          {String(time.hours).padStart(2, '0')}
        </UiTypography>
        <UiTypography as="span" weight="semibold" className="px-1 inline-block">
          :
        </UiTypography>
        <UiTypography as="span" weight="semibold">
          {String(time.minutes).padStart(2, '0')}
        </UiTypography>
        <UiTypography as="span" weight="semibold" className="px-1 inline-block">
          :
        </UiTypography>
        <UiTypography as="span" weight="semibold">
          {String(time.seconds).padStart(2, '0')}
        </UiTypography>
      </UiTypography>
    </div>
  );
};
