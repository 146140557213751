import type { EpochSubscriptionPackEntity } from '@repo/api/payment';
import {
  type PaymentGatewaySubscriptionPackEntity,
  StripeSubscriptionEntity,
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_VARIANT,
} from '@repo/api/payment';
import { CURRENCY_CODE } from '@repo/common/constants/currencies';
import { SubscriptionRecalculationVariant } from '@repo/common/services/features-book';
import { formatToBasePrice, formatToCurrency, getPriceBeforeDiscount } from '../utils/payment';

type Subscription = StripeSubscriptionEntity | EpochSubscriptionPackEntity | PaymentGatewaySubscriptionPackEntity;

const intervalToPeriod = (interval: SUBSCRIPTION_INTERVAL) => {
  switch (interval) {
    case SUBSCRIPTION_INTERVAL.ANNUAL:
      return SUBSCRIPTION_PERIOD.YEAR;
    case SUBSCRIPTION_INTERVAL.MONTHLY:
      return SUBSCRIPTION_PERIOD.MONTH;
    case SUBSCRIPTION_INTERVAL.QUARTERLY:
      return SUBSCRIPTION_PERIOD.QUARTER;
    case SUBSCRIPTION_INTERVAL.WEEKLY:
      return SUBSCRIPTION_PERIOD.WEEK;
  }
};

const periodToInterval = (period: SUBSCRIPTION_PERIOD) => {
  switch (period) {
    case SUBSCRIPTION_PERIOD.YEAR:
      return SUBSCRIPTION_INTERVAL.ANNUAL;
    case SUBSCRIPTION_PERIOD.MONTH:
      return SUBSCRIPTION_INTERVAL.MONTHLY;
    case SUBSCRIPTION_PERIOD.QUARTER:
      return SUBSCRIPTION_INTERVAL.QUARTERLY;
    case SUBSCRIPTION_PERIOD.WEEK:
      return SUBSCRIPTION_INTERVAL.WEEKLY;
  }
};

export const calcDailyPrices = (price: number) => {
  return {
    [SUBSCRIPTION_INTERVAL.WEEKLY]: {
      original: formatToBasePrice(price / 7),
      currency: formatToCurrency(price / 7),
    },
    [SUBSCRIPTION_INTERVAL.MONTHLY]: {
      original: formatToBasePrice(price / 30),
      currency: formatToCurrency(price / 30),
    },
    [SUBSCRIPTION_INTERVAL.QUARTERLY]: {
      original: formatToBasePrice(price / 90),
      currency: formatToCurrency(price / 90),
    },
    [SUBSCRIPTION_INTERVAL.ANNUAL]: {
      original: formatToBasePrice(price / 365),
      currency: formatToCurrency(price / 365),
    },
  };
};

export const calcWeeklyPrices = (price: number) => {
  return {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: {
      original: formatToBasePrice(price / 4),
      currency: formatToCurrency(price / 4),
    },
    [SUBSCRIPTION_INTERVAL.QUARTERLY]: {
      original: formatToBasePrice(price / 12),
      currency: formatToCurrency(price / 12),
    },
    [SUBSCRIPTION_INTERVAL.ANNUAL]: {
      original: formatToBasePrice(price / 48),
      currency: formatToCurrency(price / 48),
    },
  };
};

export const calcMonthlyPrices = (price: number) => {
  return {
    [SUBSCRIPTION_INTERVAL.QUARTERLY]: {
      original: formatToBasePrice(price / 3),
      currency: formatToCurrency(price / 3),
    },
    [SUBSCRIPTION_INTERVAL.ANNUAL]: {
      original: formatToBasePrice(price / 12),
      currency: formatToCurrency(price / 12),
    },
  };
};

export const recalculateSubscriptionPrices = (price: number) => {
  return {
    [SubscriptionRecalculationVariant.DAY]: calcDailyPrices(price),
    [SubscriptionRecalculationVariant.WEEK]: calcWeeklyPrices(price),
    [SubscriptionRecalculationVariant.MONTH]: calcMonthlyPrices(price),
    [SubscriptionRecalculationVariant.NONE]: {
      original: formatToBasePrice(price),
      currency: formatToCurrency(price),
    },
  };
};

const isPopularStripeSubs = (variant: SUBSCRIPTION_VARIANT) => {
  return variant === SUBSCRIPTION_VARIANT.PRO_PLUS_ANNUAL || variant === SUBSCRIPTION_VARIANT.PRO_ANNUAL;
};

// const isPopularSubs = (period: SUBSCRIPTION_INTERVAL) => {
//   return period === SUBSCRIPTION_INTERVAL.ANNUAL;
// };

const isTrial = (subs: Subscription) => {
  if ('initial_amount' in subs) {
    return subs.initial_amount === 0;
  }
  return false;
};

const isStripeSubscription = (subscription: Subscription): subscription is StripeSubscriptionEntity => {
  return (subscription as StripeSubscriptionEntity).recurring !== undefined;
};

export const subscriptionPackAdapter = (subscription: Subscription) => {
  const commonValue = {
    id: subscription.id,
    isTrial: isTrial(subscription),
  };
  if (isStripeSubscription(subscription)) {
    return {
      ...commonValue,
      period: periodToInterval(subscription.recurring.interval),
      interval: subscription.recurring.interval,
      priceKey: subscription.lookup_key,
      isPopular: isPopularStripeSubs(subscription.lookup_key),
      price: formatToBasePrice(subscription.unit_amount),
      // currencyPrice: formatToCurrency(subscription.unit_amount),
      initialAmount: null,
      originalPrice: subscription.unit_amount,
      discount: 0,
      currencyPrice: (code: CURRENCY_CODE = CURRENCY_CODE.USD) => formatToCurrency(subscription.unit_amount, code),
      priceBeforeDiscount: (discount: number, code: CURRENCY_CODE = CURRENCY_CODE.USD) =>
        getPriceBeforeDiscount(subscription.unit_amount, discount, code),
    };
  }

  const interval = intervalToPeriod(subscription.subscription_period);

  return {
    ...commonValue,
    period: subscription.subscription_period,
    interval,
    priceKey: subscription.subscription_plan,
    isPopular: subscription.is_best,
    price: formatToBasePrice(subscription.price),
    initialAmount: subscription?.initial_amount ?? null,
    originalPrice: subscription.price,
    discount: subscription.sale_percentage ?? 0,
    currencyPrice: (code: CURRENCY_CODE = CURRENCY_CODE.USD) => formatToCurrency(subscription.price, code),
    priceBeforeDiscount: (discount: number, code: CURRENCY_CODE = CURRENCY_CODE.USD) =>
      getPriceBeforeDiscount(subscription.price, discount, code),
  };
};

//Stripe
// annualCurrencyMonthPrice:
//   subscription.recurring.interval === SUBSCRIPTION_PERIOD.YEAR
//     ? calcAnnualCurrencyPriceInMonth(subscription.unit_amount)
//     : null,
// annualPriceInMonth:
//   subscription.recurring.interval === SUBSCRIPTION_PERIOD.YEAR
//     ? calcAnnualPriceInMonth(subscription.unit_amount)
//     : null,
// Other
// annualCurrencyMonthPrice:
//   subscription.subscription_period === SUBSCRIPTION_INTERVAL.ANNUAL
//     ? calcAnnualCurrencyPriceInMonth(subscription.price)
//     : null,
// annualPriceInMonth:
//   subscription.subscription_period === SUBSCRIPTION_INTERVAL.ANNUAL
//     ? calcAnnualPriceInMonth(subscription.price)
//     : null,

export type SubscriptionPackAdaptEntity = ReturnType<typeof subscriptionPackAdapter>;

export const subscriptionPackListAdapter = (subscriptions: Subscription[]) =>
  subscriptions.map(subscriptionPackAdapter);
