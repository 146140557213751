import { useCallback, useMemo, useState } from 'react';
import type { Nullable, ReactSlot } from '@repo/common/types/helpers';
import { renderSlot } from '@repo/common/utils/component';
import { useUiDialogContext } from '@repo/ui-kit/ui-dialogs';
import { UiTypography } from '@repo/ui-kit/ui-typography';
import { BannerBox } from '../-ui/banner-box';
import { type Benefit, BenefitsCarousel } from '../-ui/benefits-carousel';
import { useInvalidatedGetMeQuery } from '../../../../entities/user/queries';
import type { SubscriptionPackAdaptEntity } from '../../../../shared/adapters';
import { CheckoutButton } from '../../checkout-button';
import type { SubscriptionPriceListProps } from '../../subscription-price-list';
import { SubscriptionPriceList } from '../../subscription-price-list';
import type { BasePaywallProps } from '../_types';

export interface Props extends BasePaywallProps {
  bannerSlot?: ReactSlot;

  captionSlot?: ReactSlot;

  packItemSlot?: SubscriptionPriceListProps['packItemSlot'];

  benefits?: Benefit[];
}

export const RightSection = (props: Props) => {
  const { bannerSlot, benefits, packItemSlot, captionSlot, onCheckout, onFailed, onSuccess } = props;

  const [pack, setPack] = useState<Nullable<SubscriptionPackAdaptEntity>>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const invalidateMeQuery = useInvalidatedGetMeQuery();
  const dialogContext = useUiDialogContext();

  const onInnerSuccess = useCallback(async () => {
    await invalidateMeQuery();
    dialogContext.onClose();
    onSuccess?.(pack);
  }, [onSuccess, pack]);

  const onInnerFailed = useCallback(
    (err: unknown) => {
      onFailed?.(pack, err);
    },
    [onFailed, pack],
  );

  const onInnerCheckout = useCallback(() => {
    onCheckout?.(pack);
  }, [onCheckout, pack]);

  const _captionSlot = useMemo(() => {
    if (!captionSlot && pack) {
      return (
        <UiTypography weight="medium" variant="sm" className="mod-text-[--ui-color-secondary] mod-text-center mod-pt-2">
          Yearly price billed as {pack?.price}. You can cancel any time.
        </UiTypography>
      );
    }

    return renderSlot(captionSlot);
  }, [captionSlot, pack]);

  const onStartProcess = useCallback(() => setIsProcessing(true), []);

  const onEndProcess = useCallback(() => setIsProcessing(false), []);

  return (
    <div className="mod-relative mod-size-full mod-flex mod-flex-col mod-justify-between  tablet:mod-px-4 tablet:mod-pt-6 tablet:mod-pb-4">
      <div>
        <div className="mod-absolute mod-z-2 mod-inset-x-0 mod-top-0 mod-transform -mod-translate-y-[calc(100%+16px)] mod-flex mod-flex-col mod-justify-center mod-items-center mod-text-center tablet:mod-relative tablet:mod-translate-y-0 mod-w-full">
          <UiTypography weight="bold" variant="4xl" className="mod-text-white tablet:mod-text-[--ui-color-primary]">
            Chat With No Limits
          </UiTypography>
          <BenefitsCarousel
            benefitTitleClass="mod-text-white tablet:mod-text-[--ui-color-primary]"
            benefits={benefits ?? []}
          />
        </div>
        <BannerBox className="tablet:mod-mt-5 mod-relative mod-z-1">{renderSlot(bannerSlot)}</BannerBox>
      </div>
      <div className="mod-px-3 mod-pt-5 mod-pb-3 mod-flex mod-flex-col mod-overflow-hidden  mod-shrink tablet:mod-p-0 tablet:mod-pt-5">
        <SubscriptionPriceList disabled={isProcessing} onSelectPrice={setPack} packItemSlot={packItemSlot} />
        <div className="mod-pt-3.5 mod-grow mod-shrink-0">
          <CheckoutButton
            onSuccess={onInnerSuccess}
            onCheckout={onInnerCheckout}
            onFailed={onInnerFailed}
            onStartProcess={onStartProcess}
            onEndProcess={onEndProcess}
            size="lg"
            packId={pack?.id ?? null}
          >
            Continue
          </CheckoutButton>
          {_captionSlot}
        </div>
      </div>
    </div>
  );
};
