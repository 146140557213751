import { SUBSCRIPTION_TYPE } from '@repo/api/payment';
import { PaymentSystemFlag, useGlobalFeatureFlagContext } from '@repo/common/services/features-book';
import { mergeComponents } from '@repo/common/utils/component';
import type { GeneralCheckoutButtonProps } from './_types';
import { Epoch } from './epoch';
import { PaymentGateway } from './payment-gateway';
import { Stripe } from './stripe';
import { Wasabi } from './wasabi';

export interface Props extends GeneralCheckoutButtonProps {}

const _CheckoutButton = (props: Props) => {
  const { subscriptionVariant = SUBSCRIPTION_TYPE.PRO, product = 'subscription', ...restProps } = props;

  const { paymentSystem } = useGlobalFeatureFlagContext();

  switch (paymentSystem) {
    case PaymentSystemFlag.Stripe:
      return <Stripe product={product} subscriptionVariant={subscriptionVariant} {...restProps} />;
    case PaymentSystemFlag.Epoch:
      return <Epoch product={product} subscriptionVariant={subscriptionVariant} {...restProps} />;
    case PaymentSystemFlag.PaymentGateway:
      return <PaymentGateway product={product} subscriptionVariant={subscriptionVariant} {...restProps} />;
    case PaymentSystemFlag.Wasabi:
      return <Wasabi product={product} subscriptionVariant={subscriptionVariant} {...restProps} />;
    case PaymentSystemFlag.Fintm:
      return null;
    default:
      return null;
  }
};

export const CheckoutButton = mergeComponents(_CheckoutButton, {
  Epoch,
  Stripe,
  PaymentGateway,
  Wasabi,
});
