import { type MouseEventHandler, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Nullable } from '@repo/common/types/helpers';
import type { PaymentStatusActions } from '@repo/common/types/props';
import { UiButton } from '@repo/ui-kit/ui-button';
import { useUiDialogContext, withDialog } from '@repo/ui-kit/ui-dialogs';
import { UiFieldBox } from '@repo/ui-kit/ui-field-box';
import { UiFormInput } from '@repo/ui-kit/ui-form-input';
import { z } from 'zod';
import { useWasabiCheckout } from '../use-wasabi-checkout';
import type { GeneralCheckoutButtonProps } from './_types';
import { BaseButton } from './base-button';

const schema = z.object({
  personId: z.string({
    message: 'Person ID is required',
  }),
  accountId: z
    .string({
      message: 'Account ID is required',
    })
    .email({ message: 'Invalid Account ID' }),
  firstName: z.string({
    message: 'First name is required',
  }),
  lastName: z.string({
    message: 'Last name is required',
  }),
  address: z.string({
    message: 'Address is required',
  }),
  phone: z
    .string({
      message: 'Phone is required',
    })
    .refine(
      (v) => {
        const n = Number(v);
        return !isNaN(n) && v?.length > 0;
      },
      { message: 'Invalid number' },
    ),
});

type FormValues = z.infer<typeof schema>;

const KEY = 'wasabi-details';

const DetailsDialog = withDialog(
  ({
    packId,
    onEndProcess,
    onStartProcess,
    onSuccess,
    ...restProps
  }: {
    packId: Nullable<string>;
    onStartProcess?: () => void;
    onEndProcess?: () => void;
  } & PaymentStatusActions) => {
    const dialogCtx = useUiDialogContext();

    const form = useForm<FormValues>({ resolver: zodResolver(schema) });

    const errors = form.formState.errors;

    const onInnerSuccess = () => {
      onSuccess?.();
      dialogCtx.onClose();
    };

    const { onCheckout, isPending } = useWasabiCheckout({
      packId,
      onEndProcess,
      onStartProcess,
      onSuccess: onInnerSuccess,
      ...restProps,
    });

    const onSubmit = form.handleSubmit(async (data) => {
      onCheckout({
        account_id: data.accountId,
        first_name: data.firstName,
        last_name: data.lastName,
        person_id: data.personId,
        phone: data.phone,
        address: data.address,
      });
    });

    return (
      <div className="mod-px-4 mod-py-4">
        <form onSubmit={onSubmit} className="mod-flex mod-flex-col mod-gap-4">
          <UiFieldBox error={errors?.personId?.message} label="Person ID">
            <UiFormInput control={form.control} name="personId" />
          </UiFieldBox>
          <UiFieldBox error={errors?.accountId?.message} label="Account ID">
            <UiFormInput type="email" control={form.control} name="accountId" />
          </UiFieldBox>
          <div className="mod-grid mod-grid-cols-2 mod-gap-3">
            <UiFieldBox error={errors?.firstName?.message} label="First name">
              <UiFormInput control={form.control} name="firstName" />
            </UiFieldBox>
            <UiFieldBox error={errors?.lastName?.message} label="Last name">
              <UiFormInput control={form.control} name="lastName" />
            </UiFieldBox>
          </div>
          <UiFieldBox error={errors?.address?.message} label="Address">
            <UiFormInput control={form.control} name="address" />
          </UiFieldBox>
          <UiFieldBox error={errors?.phone?.message} label="Phone">
            <UiFormInput control={form.control} name="phone" />
          </UiFieldBox>
          <div className="w-full pt-5">
            <UiButton disabled={isPending} type="submit" size="lg">
              Continue
            </UiButton>
          </div>
        </form>
      </div>
    );
  },
  KEY,
  {
    props: {
      placement: 'center',
    },
  },
);

export interface Props extends GeneralCheckoutButtonProps {}

export const Wasabi = (props: Props) => {
  const { packId, onClick, onCheckout, onSuccess, onFailed, onStartProcess, onEndProcess, ...restProps } = props;

  const onHandleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      onCheckout?.();
      DetailsDialog.open({ packId, onSuccess, onFailed, onStartProcess, onEndProcess });
      onClick?.(e);
    },
    [onCheckout, onClick, onEndProcess, onFailed, onStartProcess, onSuccess, packId],
  );

  return <BaseButton onClick={onHandleClick} {...restProps} />;
};
