import { LabelHTMLAttributes } from 'react';

import { Slot, Slottable } from '@radix-ui/react-slot';
import { cn } from '@repo/common/utils/component';

export interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  asChild?: boolean;
}

export const UiLabel = (props: Props) => {
  const { asChild, children, className, ...restProps } = props;

  const Component = asChild ? Slot : 'label';

  return (
    <Component className={cn('ui-label ui-font-medium text-[--ui-color-secondary]', className)} {...restProps}>
      <Slottable>{children}</Slottable>
    </Component>
  );
};
