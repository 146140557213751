import { Slot } from '@radix-ui/react-slot';
import type { ReactSlot } from '@repo/common/types/helpers';
import { DialogType } from '@repo/ui-kit/ui-dialogs';
import { withDialog } from '@repo/ui-kit/ui-dialogs';
import { UiHighlight } from '@repo/ui-kit/ui-highlight';
import { UiTypography } from '@repo/ui-kit/ui-typography';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { BasePaywallLayoutDialog } from '../../../../entities/payment/ui/base-paywall-layout-dialog';
import { SubscriptionPriceListProps } from '../../subscription-price-list';
import type { BasePaywallProps } from '../_types';
import { LeftSection } from './left-section';
import { RightSection } from './right-section';

const KEY = 'subscription-paywall-dialog-create-ai';

const classes = cva('', {
  variants: {
    size: {
      md: 'tablet:mod-w-[740px] tablet:mod-h-[412px] mod-h-full',
      lg: 'tablet:mod-w-[740px] tablet:mod-h-[512px] mod-h-full',
    },
  },
});

export interface Props extends BasePaywallProps, VariantProps<typeof classes> {
  characterImgSrc: string;

  title?: string;

  highlightTitleQuery?: string;

  bannerSlot?: ReactSlot;

  captionSlot?: ReactSlot;

  packItemSlot?: SubscriptionPriceListProps['packItemSlot'];

  characterName: string;
}

export const CreateAi = (props: Props) => {
  const {
    bannerSlot,
    captionSlot = 'You can cancel your subscription at any time',
    title,
    highlightTitleQuery,
    characterImgSrc,
    packItemSlot,
    characterName,
    size = 'md',
    onSuccess,
    onCheckout,
    onFailed,
  } = props;

  const titleNode = (
    <UiTypography variant="4xl" weight="bold" className="mod-text-[--ui-color-primary]">
      <UiHighlight text={title ?? 'Unlock YOUR AI Girl'} query={highlightTitleQuery ?? 'YOUR'} />
    </UiTypography>
  );

  return (
    <BasePaywallLayoutDialog
      className={classes({ size })}
      leftSection={<LeftSection title={titleNode} characterImgSrc={characterImgSrc} characterName={characterName} />}
      rightSection={
        <RightSection
          captionSlot={captionSlot}
          packItemSlot={packItemSlot}
          bannerSlot={bannerSlot}
          onSuccess={onSuccess}
          onCheckout={onCheckout}
          onFailed={onFailed}
          title={<Slot className="mod-hidden tablet:mod-block mod-pb-4">{titleNode}</Slot>}
        />
      }
    />
  );
};

export const createAi = withDialog(CreateAi, KEY, {
  type: DialogType.Modal,
  props: {
    closeBtnProps: {
      placement: { phone: 'top-right', laptop: 'top-left' },
    },
  },
});
