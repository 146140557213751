import { HTMLAttributes, ReactNode } from 'react';

import { UiButtonProps } from '../ui-button';
import { CloseButtonProps } from './_ui/close-button';
import { ValueByMedia } from '@repo/common/hooks/use-value-by-media';
import type { ReactSlot } from '@repo/common/types/helpers';
import { ClassNames, Nullable } from '@repo/common/types/helpers';

export enum DialogType {
  Alert = 'alert',
  Modal = 'modal',
}

export type DialogPlacement = 'top' | 'bottom' | 'left' | 'right' | 'center';

export type CloseBtnPlacement = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export type DialogProps = Omit<HTMLAttributes<HTMLDivElement>, 'id'> & {
  id: string;
  closeOnEsc?: boolean;
  // withCloseButton?: boolean;
  onClose?: (id: string) => void;
  zIndex?: Nullable<number>;
  onMount?: () => void;
  onUnmount?: () => void;
  placement?: ValueByMedia<DialogPlacement>;
  classNames?: ClassNames<'root' | 'overlay'>;
  fullScreen?: ValueByMedia<boolean>;
  fullScreenGap?: ValueByMedia<boolean>;
  showCloseBtn?: boolean;
  onExit?: () => void;
  closeBtnProps?: CloseButtonProps & {
    placement?: ValueByMedia<CloseBtnPlacement>;
  };
};

export type ModalProps = DialogProps & {};

export type AlertProps = Omit<DialogProps, 'title'> & {
  closeDelayMs?: Nullable<number>;
  variant?: Nullable<'success' | 'error' | 'warning' | 'info'>;
  titleSlot?: ReactSlot;
  subtitleSlot?: ReactSlot;
  onOk?: () => void;
  okText?: string;
  okProps?: Omit<UiButtonProps, 'onClick' | 'children'>;
  cancelProps?: Omit<UiButtonProps, 'onClick' | 'children'>;
  cancelText?: string;
  onCancel?: () => void;
  okSlot?: ReactSlot;
  cancelSlot?: ReactSlot;
  hiddenCancelBtn?: boolean;
  hiddenOkBtn?: boolean;
};

export type BaseOpenDialogPayload = {
  id?: string;
  content: ReactNode;
  hidePrevDialogs?: boolean;
};

export type OpenAlertPayload = Omit<BaseOpenDialogPayload, 'content'> & {
  content?: ReactNode;
  type?: DialogType.Alert;
  titleSlot: ReactSlot;
  closeDelayMs?: Nullable<number>;
  subtitleSlot?: ReactSlot;
  onOk?: () => void;
  onCancel?: () => void;
  okSlot?: ReactSlot;
  okText?: string;
  cancelText?: string;
  cancelSlot?: ReactSlot;
  variant?: AlertProps['variant'];
  hiddenCancelBtn?: boolean;
  hiddenOkBtn?: boolean;
  props?: Omit<AlertProps, keyof Omit<OpenAlertPayload, 'props'>>;
};

export type OpenModalPayload = BaseOpenDialogPayload & {
  type?: DialogType.Modal;
  props?: Omit<ModalProps, 'id'>;
};

export type OpenDialogPayload = OpenAlertPayload | OpenModalPayload;

// export type OpenDialogPayload<T extends DialogType = DialogType.Modal> = {
//   content: ReactNode;
//   id?: string;
//   hidePrevDialogs?: boolean;
//   type?: T;
//   props?: T extends DialogType.Modal ? Omit<ModalProps, 'id'> : Omit<AlertProps, 'id'>;
// };

// export type OpenAlertPayload = {
//   type?: DialogType.Alert;
//   content: ReactNode;
//   id?: string;
//   hidePrevDialogs?: boolean;
//   onMount?: () => void;
//   onUnmount?: () => void;
//   variant?: 'success' | 'error' | 'warning' | 'info';
//   title?: ReactSlot;
//   subtitle?: ReactSlot;
//   onOk?: () => void;
//   onCancel?: () => void;
//   okSlot?: ReactSlot;
//   cancelSlot?: ReactSlot;
//   props?: Omit<AlertProps, 'id' | 'onMount' | 'onUnmount'>;
// };
//
// export type OpenModalPayload = {
//   type?: DialogType.Modal;
//   content: ReactNode;
//   id?: string;
//   hidePrevDialogs?: boolean;
//   onMount?: () => void;
//   onUnmount?: () => void;
//   props?: Omit<ModalProps, 'id'>;
// };
//
// export type OpenDialogPayload = OpenAlertPayload | OpenModalPayload;

export type DialogItem = {
  id: string;
  content: ReactNode;
  isHidden: boolean;
  onClose: () => void;
} & (
  | { type: DialogType.Alert; props?: AlertProps }
  | {
      type: DialogType.Modal;
      props?: ModalProps;
    }
);
