import type { ReactNode } from 'react';
import { createSafeContext } from '../../utils/context';
import {
  AuthFlowVariantFlag,
  PaymentSystemFlag,
  SubscriptionPaywallVariant,
  SubscriptionPriceVariant,
  useAuthFlowVariantGetter,
  usePaymentSystemGetter,
  useSubscriptionPaywallVariantGetter,
  useSubscriptionPriceVariantGetter,
} from './feature-flags';

interface GlobalFeatureFlagContext {
  paymentSystem: PaymentSystemFlag;
  authFlowVariant: AuthFlowVariantFlag;
  subsPaywallVariant: SubscriptionPaywallVariant;
  subsPriceVariant: SubscriptionPriceVariant;
}

const [Provider, useGlobalFeatureFlagContext] = createSafeContext<GlobalFeatureFlagContext>('GlobalFeatureFlagContext');

const GlobalFeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const paymentSystem = usePaymentSystemGetter();
  const subsPaywallVariant = useSubscriptionPaywallVariantGetter();
  const subsPriceVariant = useSubscriptionPriceVariantGetter();

  const authFlowVariant = useAuthFlowVariantGetter();

  const value = {
    paymentSystem: paymentSystem(),
    authFlowVariant: authFlowVariant(),
    subsPaywallVariant: subsPaywallVariant(),
    subsPriceVariant: subsPriceVariant(),
  };

  return <Provider value={value}>{children}</Provider>;
};

export { GlobalFeatureFlagProvider, useGlobalFeatureFlagContext };
