import { memo, useCallback, useEffect, useMemo } from 'react';

import { CHARACTER_STATUS } from '@repo/api/chat';
import { AuthFlowVariantFlag, useGlobalFeatureFlagContext } from '@repo/common/services/features-book';
import { useGetCharacterList } from '@repo/modules/entity/chat-queries';
import { useIsSubscribed } from '@repo/modules/entity/user-queries';
import { Link } from '@tanstack/react-router';
import sortBy from 'lodash.sortby';

import { ConversationItem } from '@/features/conversation-item';
import { authModalAction, buySubscriptionModal, welcomeOfferModalAction } from '@/features/modals';

import { authStorageGetters } from '@/entities/auth';
import { useBrandFeatures } from '@/entities/brand';
import { ConversationPreview, useConversationSidebarContext, useGetChatIdParam } from '@/entities/conversation';
import { useGetConversations } from '@/entities/conversation/libs/hooks';
import { useWelcomeOffer, welcomeOfferStorageSetters } from '@/entities/subscription';

import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { ScrollArea } from '@/shared/ui/scroll-area';

export const CharacterConversationsList = memo(() => {
  const chatListQuery = useGetConversations();
  const characterListQuery = useGetCharacterList();

  const chatId = useGetChatIdParam();

  const userHasSubscription = useIsSubscribed();

  const { authFlowVariant } = useGlobalFeatureFlagContext();

  const withAuth = AuthFlowVariantFlag.AUTH_BEFORE_ACTION === authFlowVariant;

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const features = useBrandFeatures();

  const { onCloseSidebar } = useConversationSidebarContext();

  const { isWelcomeOfferShow } = useWelcomeOffer();

  const sortedConversationList = useMemo(
    (): ConversationPreview[] =>
      sortBy(chatListQuery?.data ?? [], [(o: ConversationPreview) => o.last_message?.created_at]).reverse(),
    [chatListQuery?.data],
  );

  const onOpenSubscription = useCallback((characterPicture?: string) => {
    buySubscriptionModal.open(
      {
        characterPicture,
        analyticData: {
          placement: 'pro_dialogs',
          type: 'subscription',
        },
      },
      {
        props: {
          onMount: () => {
            Analytic.subscribeFromMainScreenClick();
          },
        },
      },
    );
  }, []);

  const handleClickItem = useCallback(
    (conversation: ConversationPreview) => {
      Analytic.conversationClick({ persona_id: conversation?.character_id, persona_name: conversation?.name });
      const char = characterListQuery?.data?.find((c) => c.id === conversation.character_id);

      if (!isAuthenticated && withAuth && !userHasSubscription && conversation.is_premium) {
        authModalAction.open(
          {
            characterPicture: char?.picture_url,
            onSuccess: (user) => {
              if (!user.is_subscribed) onOpenSubscription(char?.picture_url);
            },
            analyticData: { persona_id: conversation?.character_id, persona_name: conversation?.name },
          },
          {
            afterClose: () => onOpenSubscription(char?.picture_url),
          },
        );
        return;
      }

      if (!userHasSubscription && conversation.is_premium) {
        onOpenSubscription(char?.picture_url);
      } else {
        onCloseSidebar();
      }
    },
    [isAuthenticated, features, userHasSubscription, characterListQuery?.data],
  );

  const onOpenWelcomeOffer = () => {
    welcomeOfferModalAction.open(
      {
        onSuccessPayment: () => {
          welcomeOfferModalAction.close();
        },
        analyticData: {
          placement: 'welcome_offer',
        },
      },
      {
        afterClose: () => {
          welcomeOfferStorageSetters.setWelcomeOfferHideState();
        },
      },
    );
  };

  useEffect(() => {
    if (isWelcomeOfferShow && !chatId) {
      onOpenWelcomeOffer();
    }
  }, [isWelcomeOfferShow, chatId]);

  return (
    <ScrollArea className="flex flex-col h-px grow">
      <ul>
        {sortedConversationList.map((conversation) => {
          const isNotAllowed = !userHasSubscription && conversation.is_premium;
          return (
            <li key={conversation.id} className="relative">
              <Link
                disabled={isNotAllowed}
                to="/conversations/$chatId"
                params={{ chatId: conversation.id.toString() }}
                onClick={() => handleClickItem(conversation)}
              >
                {({ isActive }: { isActive: boolean }) => {
                  return (
                    <ConversationItem
                      status={conversation.status as never as CHARACTER_STATUS}
                      characterId={conversation.character_id}
                      lastMessage={conversation.last_message}
                      unreadCount={conversation.unread_count}
                      className={cn({ 'bg-grey-700': isActive })}
                    />
                  );
                }}
              </Link>
            </li>
          );
        })}
      </ul>
    </ScrollArea>
  );
});
