import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';
import { useEpochCheckout } from '../use-epoch-checkout';
import type { GeneralCheckoutButtonProps } from './_types';
import { BaseButton } from './base-button';

export interface Props extends GeneralCheckoutButtonProps {}

export const Epoch = (props: Props) => {
  const {
    packId,
    subscriptionVariant: _subscriptionVariant,
    onClick,
    onCheckout: _onCheckout,
    onSuccess,
    onFailed,
    onStartProcess,
    onEndProcess,
    ...restProps
  } = props;

  const { onCheckout, isPending } = useEpochCheckout({
    packId,
    onSuccess,
    onFailed,
    onStartProcess,
    onEndProcess,
  });

  const onHandleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      onCheckout();
      _onCheckout?.();
      onClick?.(e);
    },
    [onClick, packId],
  );

  return <BaseButton isPending={isPending} onClick={onHandleClick} {...restProps} />;
};
