import { useEffect, useRef } from 'react';
import type { Nullable, ReactSlot } from '@repo/common/types/helpers';
import { cn, renderSlot } from '@repo/common/utils/component';
import { Autoplay, CarouselApi, Pagination, UiCarousel, UiCarouselProps } from '@repo/ui-kit/ui-carousel';
import { UiTypography } from '@repo/ui-kit/ui-typography';

export type Benefit = {
  key: string | number;
  title: string;
  icon?: string;
};

export interface Props extends UiCarouselProps {
  benefits: Benefit[];

  benefitSlot?: ReactSlot<Benefit>;

  benefitTitleClass?: string;
}

export const BenefitsCarousel = (props: Props) => {
  const { benefits, benefitSlot, benefitTitleClass, wrapperClass, ...restProps } = props;

  const paginationEl = useRef<HTMLDivElement>(null);

  const carouselApi = useRef<Nullable<CarouselApi>>(null);

  const pagination: UiCarouselProps['pagination'] = {
    clickable: true,
    el: '.carousel-pagination',
    type: 'bullets',
    enabled: true,
    renderBullet: function (idx, className) {
      return `<span key="${idx}" class="${className} mod-inline-block mod-rounded-full mod-bg-[--ui-color-brand-primary]"></span>`;
    },
  };

  useEffect(() => {
    if (carouselApi.current) {
      carouselApi.current.pagination.init();
    }
  }, [benefits]);

  return (
    <div className="mod-w-full">
      <UiCarousel
        wrapperClass={cn('mod-relative mod-pb-4', wrapperClass)}
        direction="horizontal"
        modules={[Pagination, Autoplay]}
        pagination={pagination}
        onSwiper={(api) => (carouselApi.current = api)}
        autoplay={{
          pauseOnMouseEnter: true,
        }}
        centeredSlidesBounds
        wrapperTag="ul"
        slidesPerView={1}
        {...restProps}
      >
        {benefits.map((benefit) => {
          const _benefitSlot = renderSlot(benefitSlot, [benefit]);
          return (
            <UiCarousel.Slide tag="li" key={benefit.key} className="mod-cursor-default">
              <div key={benefit.key}>
                {_benefitSlot ? (
                  _benefitSlot
                ) : (
                  <div className="mod-inline-flex mod-items-center mod-gap-1.5">
                    {benefit.icon && (
                      <span
                        className="mod-size-6 mod-inline-block mod-bg-center mod-bg-contain mod-bg-no-repeat mod-shrink-0"
                        style={{ backgroundImage: `url(${benefit.icon})` }}
                      />
                    )}
                    <UiTypography select={false} className={benefitTitleClass} variant="2sm" weight="semibold">
                      {benefit.title}
                    </UiTypography>
                  </div>
                )}
              </div>
            </UiCarousel.Slide>
          );
        })}
      </UiCarousel>
      <div
        ref={paginationEl}
        className="mod-absolute -mod-bottom-1  mod-inset-x-0 mod-flex mod-justify-center mod-items-center mod-gap-1.5"
      >
        <div className="carousel-pagination"></div>
      </div>
    </div>
  );
};
