import type { CreateStripeSubscriptionDTO, CreateStripeSubscriptionResponse } from '@repo/api/payment';
import { PaymentRepository } from '@repo/api/payment';
import type { InnerUseMutationOptions } from '@repo/common/types/react-query';
import { extractCookieValue } from '@repo/common/utils/helpers';
import { useMutation } from '@tanstack/react-query';
import { CookiesDictionary } from '../../../shared/constants/cookies-dictionary';

export const useCreateStripeSubscriptionGateway = (
  options?: InnerUseMutationOptions<CreateStripeSubscriptionResponse, unknown, CreateStripeSubscriptionDTO>,
) => {
  const redTrackId = extractCookieValue(CookiesDictionary.RED_TRACK);
  return useMutation({
    mutationFn: (dto) =>
      PaymentRepository.createStripeSubscription({
        ...dto,
        ...(redTrackId && { rtkclickid_store: redTrackId }),
      }),
    ...options,
  });
};
