import { useFeature, useFeatureGetter } from '../api';

export enum PaymentSystemFlag {
  None,
  Stripe,
  Epoch,
  Fintm,
  PaymentGateway,
  Wasabi,
  Constant_Pos,
}

const KEY = 'payment-system';

export const usePaymentSystemGetter = () => {
  return useFeatureGetter(KEY, PaymentSystemFlag.None);
};

export const usePaymentSystem = () => {
  return useFeature(KEY, PaymentSystemFlag.None);
};
