import { getClient } from '../../api/api-client';
import {
  epochEndpoints,
  fintmEndpoints,
  paymentGatewayEndpoints,
  stripeEndpoints,
  wasabiGateEndpoints,
} from '../../api/api-dictionary';
import { ApiEndpoint } from '../../types/utility';
import { dataExtractor } from '../../utils/data-extractor';
import type {
  CreatePaymentGatewayPaymentIntentDTO,
  CreatePaymentGatewayPaymentIntentResponse,
  CreateWasabiPaymentIntentDTO,
  CreateWasabiPaymentIntentResponse,
  EpochCreatePaymentIntentDTO,
  EpochCreatePaymentIntentResponse,
  EpochCreditPackEntity,
  EpochSubscriptionPackEntity,
  PaymentGatewaySubscriptionPackEntity,
  WasabiCreditPackEntity,
  WasabiSubscriptionPackEntity,
} from './entities';
import {
  CreateFintmPaymentDTO,
  CreateFintmPaymentResponse,
  CreateStripeCustomerResponse,
  CreateStripePaymentDTO,
  CreateStripeSubscriptionDTO,
  CreateStripeSubscriptionResponse,
  FintmCreditPack,
  FintmSubscriptionEntity,
  GetSubscriptionPacksQuery,
  type PaymentGatewayCreditPackEntity,
  StripeCreditPack,
  StripeSubscriptionEntity,
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_VARIANT,
} from './entities';

export const getSubscriptionVariantQuery = (packType: SUBSCRIPTION_TYPE = SUBSCRIPTION_TYPE.PRO) => {
  let value: SUBSCRIPTION_VARIANT[] = [];

  if (packType === SUBSCRIPTION_TYPE.PRO) {
    value = [
      SUBSCRIPTION_VARIANT.PRO_MONTHLY,
      SUBSCRIPTION_VARIANT.PRO_WEEKLY,
      SUBSCRIPTION_VARIANT.PRO_QUARTERLY,
      SUBSCRIPTION_VARIANT.PRO_ANNUAL,
    ];
  } else {
    value = [
      SUBSCRIPTION_VARIANT.PRO_PLUS_MONTHLY,
      SUBSCRIPTION_VARIANT.PRO_PLUS_WEEKLY,
      SUBSCRIPTION_VARIANT.PRO_PLUS_QUARTERLY,
      SUBSCRIPTION_VARIANT.PRO_PLUS_ANNUAL,
    ];
  }

  return value.join(',');
};

const getStripeSubscriptions: ApiEndpoint<
  undefined,
  undefined,
  StripeSubscriptionEntity[],
  GetSubscriptionPacksQuery
> = (query, config) => {
  const lookup_keys = getSubscriptionVariantQuery(query?.lookup_keys);
  return getClient()
    .get(stripeEndpoints.getPrices(), {
      ...config,
      params: {
        lookup_keys,
      },
    })
    .then(dataExtractor);
};

const getStripeCreditPacks: ApiEndpoint<undefined, undefined, StripeCreditPack[]> = (config) => {
  return getClient().get(stripeEndpoints.creditPacks(), config).then(dataExtractor);
};

const createStripeCustomer: ApiEndpoint<undefined, undefined, CreateStripeCustomerResponse> = (config) => {
  return getClient().post(stripeEndpoints.createCustomer(), {}, config).then(dataExtractor);
};

const createStripeSubscription: ApiEndpoint<
  CreateStripeSubscriptionDTO,
  undefined,
  CreateStripeSubscriptionResponse
> = (dto, config) => {
  return getClient().post(stripeEndpoints.createSubscription(), dto, config).then(dataExtractor);
};

const createStripePayment: ApiEndpoint<CreateStripePaymentDTO, undefined, CreateStripeSubscriptionResponse> = (
  dto,
  config,
) => {
  return getClient().post(stripeEndpoints.createPaymentIntent(), dto, config).then(dataExtractor);
};

const getFintmSubscriptions: ApiEndpoint<undefined, undefined, FintmSubscriptionEntity[], GetSubscriptionPacksQuery> = (
  query,
  config,
) => {
  return getClient()
    .get(fintmEndpoints.creditPacks(), {
      ...config,
      params: query,
    })
    .then(dataExtractor);
};

const getFintmCreditPacks: ApiEndpoint<undefined, undefined, FintmCreditPack[]> = (config) => {
  return getClient().get(fintmEndpoints.creditPacks(), config).then(dataExtractor);
};

const createFintmPayment: ApiEndpoint<CreateFintmPaymentDTO, undefined, CreateFintmPaymentResponse> = (dto, config) => {
  return getClient().post(fintmEndpoints.fintmPayment(), dto, config).then(dataExtractor);
};

const getEpochCreditPacks: ApiEndpoint<undefined, undefined, EpochCreditPackEntity[]> = (config) => {
  return getClient().get(epochEndpoints.creditPacks(), config).then(dataExtractor);
};

const getEpochSubscriptionPacks: ApiEndpoint<
  undefined,
  undefined,
  EpochSubscriptionPackEntity[],
  GetSubscriptionPacksQuery
> = (query, config) => {
  return getClient()
    .get(epochEndpoints.subscriptionPacks(), {
      ...config,
      params: query,
    })
    .then(dataExtractor);
};

const createEpochPaymentIntent: ApiEndpoint<
  EpochCreatePaymentIntentDTO,
  undefined,
  EpochCreatePaymentIntentResponse
> = (dto, config) => {
  return getClient().post(epochEndpoints.createPaymentIntent(), dto, config).then(dataExtractor);
};

// Payment gateway
const getPaymentGatewayCreditPacks: ApiEndpoint<undefined, undefined, PaymentGatewayCreditPackEntity[]> = (config) => {
  return getClient().get(paymentGatewayEndpoints.creditPacks, config).then(dataExtractor);
};

const getPaymentGatewaySubscriptionPacks: ApiEndpoint<
  undefined,
  undefined,
  PaymentGatewaySubscriptionPackEntity[],
  GetSubscriptionPacksQuery
> = (query, config) => {
  return getClient()
    .get(paymentGatewayEndpoints.subscriptions, {
      ...config,
      params: query,
    })
    .then(dataExtractor);
};

const createPaymentGatewayIntent: ApiEndpoint<
  CreatePaymentGatewayPaymentIntentDTO,
  undefined,
  CreatePaymentGatewayPaymentIntentResponse
> = (dto, config) => {
  return getClient().post(paymentGatewayEndpoints.createPaymentIntent, dto, config).then(dataExtractor);
};

// Wasabi Gate

const getWasabiCreditPacks: ApiEndpoint<undefined, undefined, WasabiCreditPackEntity[]> = (config) => {
  return getClient().get(wasabiGateEndpoints.creditPacks, config).then(dataExtractor);
};

const getWasabiSubscriptionPacks: ApiEndpoint<
  undefined,
  undefined,
  WasabiSubscriptionPackEntity[],
  GetSubscriptionPacksQuery
> = (query, config) => {
  return getClient()
    .get(wasabiGateEndpoints.subscriptions, {
      ...config,
      params: query,
    })
    .then(dataExtractor);
};

const createWasabiIntent: ApiEndpoint<CreateWasabiPaymentIntentDTO, undefined, CreateWasabiPaymentIntentResponse> = (
  dto,
  config,
) => {
  return getClient().post(wasabiGateEndpoints.createPaymentIntent, dto, config).then(dataExtractor);
};

export const Repository = {
  getStripeSubscriptions,
  getStripeCreditPacks,
  createStripeCustomer,
  createStripeSubscription,
  createStripePayment,
  getFintmSubscriptions,
  createFintmPayment,
  getFintmCreditPacks,
  getEpochCreditPacks,
  getEpochSubscriptionPacks,
  createEpochPaymentIntent,
  getPaymentGatewayCreditPacks,
  getPaymentGatewaySubscriptionPacks,
  createPaymentGatewayIntent,
  getWasabiCreditPacks,
  getWasabiSubscriptionPacks,
  createWasabiIntent,
};
