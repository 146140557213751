import { GetSubscriptionPacksQuery } from '@repo/api/payment';
import { PaymentRepository } from '@repo/api/payment';
import type { InnerUseQueryOptions } from '@repo/common/types/react-query';
import type { QueryClient } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SubscriptionPackAdaptEntity } from '../../../shared/adapters';
import { subscriptionPackListAdapter } from '../../../shared/adapters';

const KEY = 'STRIPE_SUBSCRIPTION_PACKS';

export const useGetStripeSubscriptionPacks = (
  query: GetSubscriptionPacksQuery,
  options?: InnerUseQueryOptions<SubscriptionPackAdaptEntity[]>,
) => {
  return useQuery({
    queryKey: [KEY, JSON.stringify(query)],
    queryFn: async () => {
      const response = await PaymentRepository.getStripeSubscriptions(query);
      return subscriptionPackListAdapter(response);
    },
    ...options,
  });
};

export const stripeSubscriptionPacksQueryKey = (query: GetSubscriptionPacksQuery) => [KEY, query];

export const invalidateStripeSubscriptionPacksQuery = (qc: QueryClient) => qc.invalidateQueries({ queryKey: [KEY] });

export const useInvalidateStripeSubscriptionPacksQuery = () => {
  const qc = useQueryClient();
  return () => qc.invalidateQueries({ queryKey: [KEY] });
};
