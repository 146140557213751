import { cn } from '@repo/common/utils/component';
import { mergeComponents } from '@repo/common/utils/component';
import { A11y } from 'swiper/modules';
import { useSwiper } from 'swiper/react';
import { Swiper, type SwiperProps, SwiperSlide, type SwiperSlideProps } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export interface Props extends SwiperProps {}

const UiSlide = (props: SwiperSlideProps) => {
  const { className, ...restProps } = props;

  const swiper = useSwiper();

  return (
    <SwiperSlide
      className={cn(
        // '!ui-h-auto',
        {
          'last:!ui-mb-0': !swiper.isHorizontal(),
          'last:!ui-mr-0': swiper.isHorizontal(),
        },
        className,
      )}
      {...restProps}
    />
  );
};

UiSlide.displayName = 'SwiperSlide';

const _UiCarousel = (props: Props) => {
  const { className, modules, ...restProps } = props;

  const _modules = [A11y, ...(modules ?? [])];
  return <Swiper freeMode modules={_modules} className={cn('ui-size-full', className)} {...restProps} />;
};

export const UiCarousel = mergeComponents(_UiCarousel, {
  Slide: UiSlide,
});
