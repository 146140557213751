import { cn } from '@repo/common/utils/component';
import { UiAnimatedIcon } from '@repo/ui-kit/ui-icon';
import { UiTypography } from '@repo/ui-kit/ui-typography';
import type { HTMLMotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

export interface Props extends HTMLMotionProps<'ul'> {
  characterName: string;
}

export const AnimatedMessages = (props: Props) => {
  const { characterName, className } = props;

  return (
    <AnimatePresence mode="popLayout">
      <motion.ul
        className={cn('mod-max-w-[192px]', className)}
        transition={{
          duration: 1.5,
          ease: 'easeInOut',
          staggerChildren: 0.3,
          delayChildren: 0.5,
        }}
      >
        <motion.li
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{
            delay: 0.5,
            duration: 0.5,
          }}
          initial="hidden"
          animate="visible"
        >
          <UiTypography select={false} weight="semibold" variant="sm" className="mod-text-[--ui-color-secondary]">
            {characterName}
          </UiTypography>
        </motion.li>
        <li className="mod-h-1" />
        <motion.li
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{
            delay: 0.7,
            duration: 0.5,
          }}
          initial="hidden"
          animate="visible"
          className="mod-w-fit mod-p-3 mod-rounded-2xl mod-bg-[rgb(71,39,53)]"
        >
          <UiTypography select={false} as="p" weight="medium" variant="sm">
            I can't wait to discuss anything you want 💖🔥
          </UiTypography>
        </motion.li>
        <li className="mod-h-1.5" />
        <motion.li
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{
            delay: 1.2,
            duration: 0.5,
          }}
          initial="hidden"
          animate="visible"
          className="mod-w-fit mod-inline-flex mod-items-center mod-p-3 mod-rounded-2xl mod-bg-[rgb(71,39,53)]"
        >
          <UiAnimatedIcon k="spinner" />
          <UiTypography select={false} as="span" weight="medium" variant="sm" className="mod-pl-1">
            sending photo
          </UiTypography>
        </motion.li>
      </motion.ul>
    </AnimatePresence>
  );
};
