import { ReactNode, useMemo } from 'react';

import { Portal } from '../../../common';
import { DialogKey } from '../types';

import { createSafeContext } from '@/shared/libs/context';

interface Props {
  dialogKey: DialogKey;
  onClose: () => void;
  isHidden: boolean;

  children: ReactNode;
}

interface DialogProviderValue extends Pick<Props, 'dialogKey' | 'onClose' | 'isHidden'> {}

const [Provider, useDialogContext] = createSafeContext<DialogProviderValue>('DialogSkeletonContext');

const DialogProvider = (props: Props) => {
  const { dialogKey, isHidden, onClose, children } = props;

  const value: DialogProviderValue = useMemo(
    () => ({
      dialogKey,
      onClose,
      isHidden,
    }),
    [dialogKey, onClose, isHidden],
  );

  return (
    <Provider value={value}>
      <Portal target="#dialog-container">{children}</Portal>
    </Provider>
  );
};

DialogProvider.displayName = 'DialogProvider';

export { DialogProvider, useDialogContext };
