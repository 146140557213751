import { CURRENCY_CODE } from '@repo/common/constants/currencies';
import { currencyConverter } from '@repo/common/utils/currency';

export const formatToBasePrice = (price: number) => {
  return Number((price / 100).toFixed(3).slice(0, -1));
};

export const formatToCurrency = (price: number, code: CURRENCY_CODE = CURRENCY_CODE.USD) => {
  return currencyConverter(code).format(formatToBasePrice(price));
};

export const getPriceBeforeDiscount = (price: number, discount: number, code: CURRENCY_CODE = CURRENCY_CODE.USD) => {
  if (discount === 0) return null;
  const finalPrice = formatToBasePrice(price) / (1 - discount / 100);
  return {
    price: finalPrice,
    currencyPrice: currencyConverter(code).format(finalPrice),
  };
};

export const calculateMonthlyEquivalentOfAnnualPrice = (price: number) => {
  return Number((price / 100 / 12).toFixed(3).slice(0, -1));
};

export const formatMonthlyEquivalentOfAnnualPrice = (price: number) => {
  return currencyConverter(CURRENCY_CODE.USD).format(calculateMonthlyEquivalentOfAnnualPrice(price));
};
