import { cx, CxOptions } from 'class-variance-authority';
import merge from 'lodash.merge';
import type { ComponentProps, ElementType, MutableRefObject, ReactNode, Ref, SyntheticEvent } from 'react';
import { Children, cloneElement, isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactSlot } from '../types/helpers';

export function cn(...inputs: CxOptions) {
  return twMerge(cx(inputs));
}

export const mergeComponents = <T extends Function, O extends Record<string, any>>(component: T, rest: O): T & O => {
  return Object.assign(component, rest);
};

export const processReactNode = <T extends ReactNode | ((...args: unknown[]) => ReactNode)>(
  node: T,
  args?: T extends (...args: infer A) => ReactNode ? A : never,
): ReactNode => {
  const _args = Array.isArray(args) ? args : [args];
  return typeof node === 'function' ? node(...(_args as unknown[])) : node;
};

export const renderSlot = <Slot extends ReactSlot>(
  slot: Slot,
  args?: Slot extends (...args: infer A) => ReactNode ? A : never,
): ReactNode => {
  const _args = Array.isArray(args) ? args : [args];
  return typeof slot === 'function' ? slot(...(_args as never[])) : slot;
};

export const getSlot = (children: ReactNode, validTypes: ElementType[]): ReactNode => {
  return Children.map(children, (child) => {
    if (isValidElement(child)) {
      if (typeof child.type === 'string' || validTypes.includes(child.type)) {
        return child;
      }
    }
    return null;
  });
};

type SlotTypes = { [key: string]: ElementType };

type Slots<T extends SlotTypes> = {
  [_K in keyof T]: ReactNode | null;
};

export const getSlots = <T extends SlotTypes>(
  children: ReactNode,
  slotTypes: T,
  slotProps: { [K in keyof T]?: Partial<ComponentProps<T[K]>> } = {},
): Slots<T> => {
  const slots: Slots<T> = {} as Slots<T>;

  // Initialize slots with null
  Object.keys(slotTypes).forEach((key) => {
    (slots as any)[key] = null;
  });

  // Iterate over children and assign to corresponding slot
  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      const slotName = Object.keys(slotTypes).find((key) => child.type === slotTypes[key]);
      if (slotName) {
        const additionalProps = slotProps[slotName] || {};
        const mergedProps = merge({}, child.props, additionalProps);
        (slots as any)[slotName] = cloneElement(child, mergedProps);
      }
    }
  });

  return slots;
};

export const getSlotByAttr = (children: ReactNode, validSlots: string[]): ReactNode => {
  return Children.map(children, (child) => {
    if (isValidElement(child)) {
      const slotType = child.props['data-slot'];

      if (validSlots.includes(slotType)) {
        return child;
      }
    }

    return null;
  });
};

export const nextRenderTick = (cb: () => void, timeout = 0) => setTimeout(cb, timeout);

export const isPrimitiveNode = (node: ReactSlot): node is string | number => {
  return typeof node === 'string' || typeof node === 'number';
};

export const preventDefault = (e: SyntheticEvent) => {
  e.preventDefault();
};

export function setRef<T>(ref: Ref<T> | undefined, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    (ref as MutableRefObject<T | null>).current = value;
  }
}

export type PatternRender = {
  pattern: RegExp;
  render: (content: string, key: string) => ReactNode;
};

export const renderTextWithPatterns = (patterns: PatternRender[], text: string): ReactNode[] => {
  const elements: ReactNode[] = [];
  let lastIndex = 0;

  const matches = patterns
    .flatMap(({ pattern }) => [...text.matchAll(pattern)].map((match) => ({ ...match, pattern })))
    .sort((a, b) => (a.index || 0) - (b.index || 0));

  matches.forEach((match, matchIndex) => {
    if (match.index && match.index > lastIndex) {
      elements.push(text.slice(lastIndex, match.index));
    }
    const patternObj = patterns.find((p) => p.pattern === match.pattern);
    if (patternObj) {
      const key = `${match[0]}-${matchIndex}`;
      elements.push(patternObj.render(match[0], key));
    }
    lastIndex = (match.index || 0) + match[0].length;
  });

  if (lastIndex < text.length) {
    elements.push(text.slice(lastIndex));
  }

  return elements;
};

export const preventContextMenuActions = {
  onContextMenu: preventDefault,
  onMouseDown: preventDefault,
  onMouseUp: preventDefault,
  onTouchStart: preventDefault,
  onTouchEnd: preventDefault,
};
