import { useGetPaymentGatewaySubscriptionPacks } from '../../../entities/payment/queries/use-get-payment-gateway-subscription-packs';
import type { GeneralSubscriptionPriceListProps } from './_types';
import { BaseList } from './base-list';

export interface Props extends GeneralSubscriptionPriceListProps {}

export const PaymentGateway = (props: Props) => {
  const { packType, ...restProps } = props;

  const packs = useGetPaymentGatewaySubscriptionPacks({
    lookup_keys: packType,
  });

  return <BaseList packs={packs?.data ?? []} {...restProps} />;
};
