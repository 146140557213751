import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';
import type { Nullable } from '@repo/common/types/helpers';
import {
  useCreateStripeCreditGateway,
  useCreateStripeCustomer,
  useCreateStripeSubscriptionGateway,
} from '../../../entities/payment/queries';
import { stripeCheckoutDialog } from '../stripe-checkout-dialog/stripe-checkout-dialog';
import type { GeneralCheckoutButtonProps } from './_types';
import { BaseButton } from './base-button';

export interface Props extends GeneralCheckoutButtonProps {}

export const Stripe = (props: Props) => {
  const {
    product,
    packId,
    subscriptionVariant: _subscriptionVariant,
    onClick,
    onCheckout: _onCheckout,
    onSuccess,
    onFailed,
    onStartProcess,
    onEndProcess,
    ...restProps
  } = props;

  const createCustomer = useCreateStripeCustomer();

  const createSubscriptionGateway = useCreateStripeSubscriptionGateway();
  const createCreditsGateway = useCreateStripeCreditGateway();

  const isPending = createCustomer.isPending || createSubscriptionGateway.isPending || createCreditsGateway.isPending;

  const getClientSecret = async () => {
    if (!packId) return null;

    let clientSecret: Nullable<string> = null;

    if (product === 'subscription') {
      const customer = await createCustomer.mutateAsync();
      const subscription = await createSubscriptionGateway.mutateAsync({
        customerId: customer.customerId,
        priceId: packId,
      });
      clientSecret = subscription.clientSecret;
    }

    if (product === 'credits') {
      const credits = await createCreditsGateway.mutateAsync({
        pack: packId,
      });
      clientSecret = credits.clientSecret;
    }

    return clientSecret;
  };

  const onHandleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      onStartProcess?.();
      try {
        const clientSecret = await getClientSecret();
        _onCheckout?.();
        if (clientSecret) {
          stripeCheckoutDialog.open(
            {
              clientSecret,
              onFailed,
              onSuccess,
            },
            {
              hidePrevDialogs: true,
            },
          );
        }
      } catch (error) {
        onFailed?.(typeof error === 'string' ? error : 'Failed to create payment');
      } finally {
        onEndProcess?.();
      }
      onClick?.(e);
    },
    [onClick, packId],
  );

  return <BaseButton isPending={isPending} onClick={onHandleClick} {...restProps} />;
};
