import { SUBSCRIPTION_TYPE } from '@repo/api/payment';
import { PaymentSystemFlag, useGlobalFeatureFlagContext } from '@repo/common/services/features-book';
import { mergeComponents } from '@repo/common/utils/component';
import type { GeneralSubscriptionPriceListProps } from './_types';
import { Epoch } from './epoch';
import { PaymentGateway } from './payment-gateway';
import { Stripe } from './stripe';
import { Wasabi } from './wasabi';

export interface Props extends Omit<GeneralSubscriptionPriceListProps, 'packType'> {
  packType?: SUBSCRIPTION_TYPE;
}

export const _SubscriptionPriceList = (props: Props) => {
  const { packType = SUBSCRIPTION_TYPE.PRO, ...restProps } = props;

  const { paymentSystem } = useGlobalFeatureFlagContext();

  switch (paymentSystem) {
    case PaymentSystemFlag.Stripe:
      return <Stripe packType={packType} {...restProps} />;
    case PaymentSystemFlag.Epoch:
      return <Epoch packType={packType} {...restProps} />;
    case PaymentSystemFlag.PaymentGateway:
      return <PaymentGateway packType={packType} {...restProps} />;
    case PaymentSystemFlag.Wasabi:
      return <Wasabi packType={packType} {...restProps} />;
    case PaymentSystemFlag.Fintm:
      return null;
    default:
      return null;
  }
};

export const SubscriptionPriceList = mergeComponents(_SubscriptionPriceList, {
  Epoch,
  Stripe,
  PaymentGateway,
  Wasabi,
});
