import type { GetSubscriptionPacksQuery } from '@repo/api/payment';
import { PaymentRepository } from '@repo/api/payment';
import type { InnerUseQueryOptions } from '@repo/common/types/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { QueryClient } from '@tanstack/react-query';
import type { SubscriptionPackAdaptEntity } from '../../../shared/adapters';
import { subscriptionPackListAdapter } from '../../../shared/adapters';

const KEY = 'EPOCH_SUBSCRIPTION_PACKS';

export const useGetEpochSubscriptionPacks = (
  query: GetSubscriptionPacksQuery,
  options?: InnerUseQueryOptions<SubscriptionPackAdaptEntity[]>,
) => {
  return useQuery({
    ...options,
    queryKey: [KEY, query],
    queryFn: async () => {
      const data = await PaymentRepository.getEpochSubscriptionPacks(query);
      return subscriptionPackListAdapter(data);
    },
  });
};

export const epochSubscriptionPacksQueryKey = (query: GetSubscriptionPacksQuery) => [KEY, query];

export const invalidateEpochSubscriptionPacksQuery = (qc: QueryClient) => qc.invalidateQueries({ queryKey: [KEY] });

export const useInvalidateEpochSubscriptionPacksQuery = () => {
  const qc = useQueryClient();
  return () => qc.invalidateQueries({ queryKey: [KEY] });
};
