import { type ReactNode, useCallback, useMemo, useState } from 'react';
import type { ReactSlot } from '@repo/common/types/helpers';
import { renderSlot } from '@repo/common/utils/component';
import { useUiDialogContext } from '@repo/ui-kit/ui-dialogs';
import { UiTypography } from '@repo/ui-kit/ui-typography';
import { useSelectPack } from '../-api/use-select-pack';
import { BannerBox } from '../-ui/banner-box';
import { useInvalidatedGetMeQuery } from '../../../../entities/user/queries';
import { CheckoutButton } from '../../checkout-button';
import type { SubscriptionPriceListProps } from '../../subscription-price-list';
import { SubscriptionPriceList } from '../../subscription-price-list';
import type { BasePaywallProps } from '../_types';

export interface Props extends BasePaywallProps {
  title?: ReactNode;

  bannerSlot?: ReactSlot;

  captionSlot?: ReactSlot;

  packItemSlot?: SubscriptionPriceListProps['packItemSlot'];
}

export const RightSection = (props: Props) => {
  const { title, captionSlot, bannerSlot, packItemSlot, onSuccess, onCheckout, onFailed } = props;

  const [isProcessing, setIsProcessing] = useState(false);

  const [pack, onSelect] = useSelectPack();

  const invalidateMeQuery = useInvalidatedGetMeQuery();

  const dialogCtx = useUiDialogContext();

  const onInnerSuccess = useCallback(async () => {
    await invalidateMeQuery();
    dialogCtx.onClose();
    onSuccess?.(pack);
  }, [onSuccess, pack]);

  const onInnerFailed = useCallback(
    (err: unknown) => {
      onFailed?.(pack, err);
    },
    [onFailed, pack],
  );

  const onInnerCheckout = useCallback(() => {
    onCheckout?.(pack);
  }, [onCheckout, pack]);

  const _bannerSlot = useMemo(() => renderSlot(bannerSlot), [bannerSlot]);
  const _captionSlot = useMemo(() => renderSlot(captionSlot), [captionSlot]);

  const onStartProcess = useCallback(() => setIsProcessing(true), []);

  const onEndProcess = useCallback(() => setIsProcessing(false), []);

  return (
    <div className="mod-flex mod-flex-col mod-justify-between mod-size-full mod-grow tablet:mod-pt-6 tablet:px-3 tablet:pb-4">
      <div>
        {title}
        <BannerBox className="mod-relative mod-z-1">{_bannerSlot}</BannerBox>
      </div>
      <div className="mod-px-3 mod-pt-5 mod-pb-4 mod-flex mod-flex-col mod-overflow-hidden mod-shrink tablet:mod-p-0 tablet:mod-pt-5">
        <SubscriptionPriceList disabled={isProcessing} packItemSlot={packItemSlot} onSelectPrice={onSelect} />
        <div className="mod-pt-4 tablet:mod-pt-3.5">
          <CheckoutButton
            size="lg"
            onSuccess={onInnerSuccess}
            onCheckout={onInnerCheckout}
            onFailed={onInnerFailed}
            onStartProcess={onStartProcess}
            onEndProcess={onEndProcess}
            packId={pack?.id ?? null}
          >
            Continue
          </CheckoutButton>
          {pack && (
            <UiTypography
              weight="medium"
              variant="sm"
              className="mod-text-[--ui-color-secondary] mod-text-center mod-pt-2"
            >
              {_captionSlot}
            </UiTypography>
          )}
        </div>
      </div>
    </div>
  );
};
