import type { ReactNode } from 'react';
import { AnimatedMessages } from '../-ui/animated-messages';
import { ImageSection } from '../-ui/image-section';

export interface Props {
  title?: ReactNode;

  characterImgSrc: string;

  characterName: string;
}

export const LeftSection = (props: Props) => {
  const { title, characterImgSrc, characterName } = props;
  return (
    <ImageSection className="mod-flex mod-flex-col mod-justify-between mod-p-3 mod-h-full" src={characterImgSrc}>
      <div className="mod-flex mod-size-full mod-flex-col mod-justify-between mod-items-start tablet:mod-justify-end">
        <div className="mod-block tablet:mod-hidden">{title}</div>
        <AnimatedMessages characterName={characterName} />
      </div>
    </ImageSection>
  );
};
