import { HTMLAttributes, useEffect, useMemo, useState } from 'react';

import { subscriptionsContent, titleStyles } from './buy-subscription-modal.config';
import { SubscriptionTier } from '@/core/constants';
import { useFeature } from '@repo/common/services/features-book';
import type { Nullable } from '@repo/common/types/helpers';
import type { SubscriptionPackAdaptEntity } from '@repo/modules/api-adapters';
import { CheckoutButton } from '@repo/modules/checkout-button';
import { SubscriptionPriceList } from '@repo/modules/subscription-price-list';
import { dialog } from '@repo/ui-kit/ui-dialogs';

import { DiscountBanner } from '@/features/discount-banner';

import { useBrandFeatures } from '@/entities/brand';
import { ModalPreviewImg } from '@/entities/modals';
import { usePaymentAnalytic } from '@/entities/payment/hooks/use-payment-analytic';
import { PriceRadioBtn, SUBSCRIPTIONS_VIEW, SubscriptionBenefitList } from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';
import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Typography } from '@/shared/ui';
import { CloseButton } from '@/shared/ui/close-button';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  subscriptionVariant?: SubscriptionTier;

  imgSrc?: string;

  characterPicture?: string;

  analyticData?: any;

  onCheckoutPayment?: () => void;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;

  isPaymentModal?: boolean;

  onSuccessOnboarding?: () => void;
}

export const BuySubscription = (props: Props) => {
  const {
    subscriptionVariant = SubscriptionTier.PRO,
    analyticData,
    imgSrc,
    characterPicture,
    onSuccessPayment,
    onFailedPayment,
    onCheckoutPayment,
    className,
    isPaymentModal,
    onSuccessOnboarding,
  } = props;

  const features = useBrandFeatures();

  const currentContent = subscriptionsContent(features)[subscriptionVariant];

  const subscriptionView = useFeature<SUBSCRIPTIONS_VIEW>('subscription_paywall_view', SUBSCRIPTIONS_VIEW.DEFAULT);

  const [selectedSubscription, setSelectedSubscription] = useState<Nullable<SubscriptionPackAdaptEntity>>(null);

  const isBlurred = Boolean(imgSrc);

  const paymentAnalytic = usePaymentAnalytic();

  const handleSuccessPayment = () => {
    onSuccessPayment?.();
    if (!selectedSubscription) return;
    paymentAnalytic.success({
      pack: selectedSubscription,
      type: 'subscription',
    });
  };

  const handleFailedPayment = (err: unknown) => {
    const error = typeof err === 'string' ? err : undefined;
    onFailedPayment?.(error);
    if (!selectedSubscription) return;
    paymentAnalytic.failed({
      pack: selectedSubscription,
      type: 'subscription',
      error,
    });
  };

  const handleCheckoutPayment = () => {
    onCheckoutPayment?.();
    if (!selectedSubscription) return;
    paymentAnalytic.startCheckout({
      pack: selectedSubscription,
      type: 'subscription',
    });
  };

  const handleDeleteProgressAlert = () => {
    if (analyticData) {
      Analytic.paywallSkipClick(analyticData);
    }
    dialog.alert({
      variant: 'error',
      okText: 'No',
      cancelText: 'Delete',
      titleSlot: 'Are you sure you want to delete all the progress?',
      onCancel: () => {
        onSuccessOnboarding?.();
      },
      props: {
        onMount: () => {
          Analytic.quizDeleteProgressView();
        },
      },
    });
  };

  // useEffect(() => {
  //   if (subscriptionList.length > 0) {
  //     const bestSubs = subscriptionList.find((sub) => sub.isBest);
  //     setSelectedSubscription(bestSubs ?? subscriptionList[0]);
  //   }
  // }, [subscriptionList]);

  useEffect(() => {
    if (analyticData) {
      Analytic.paywallScreenView(analyticData);
    }
  }, []);

  const paywallImage = useMemo(() => {
    if (characterPicture) {
      return characterPicture;
    }
    if (imgSrc) {
      return imgSrc;
    }
    return currentContent.picture;
  }, [characterPicture, imgSrc, currentContent.picture]);

  return (
    <>
      <ModalPreviewImg
        imgSrc={paywallImage}
        isBlurred={isBlurred}
        className={cn(
          'absolute z-[1] w-full h-[calc(100%-370px)] sm:w-1/2 sm:h-full',
          { 'relative grow h-full px-3 sm:px-0 pt-14 sm:pt-0': isBlurred },
          className,
        )}
      />
      {isPaymentModal && (
        <CloseButton className="w-4 h-6 p-0.5 z-10 opacity-70 mt-3 ml-3" onClick={handleDeleteProgressAlert} />
      )}
      <div className={cn('basis-2/3 sm:basis-1/2 grow', { hidden: isBlurred })} />
      <div
        className={cn(
          'relative z-[3] py-3 pt-2 sm:pt-4 -mt-11 sm:mt-0',
          'flex flex-col justify-between shrink-0 gap-4',
          'sm:justify-between sm:basis-1/2 sm:grow',
        )}
      >
        {isBlurred ? (
          <div className="hidden sm:block px-3">
            <Typography as="h6" weight="bold" variant="2xl" className="text-primary-font pb-2">
              {features?.modal_blurred_info.title}
            </Typography>
            <Typography as="span" weight="semibold" variant="base" className="text-secondary">
              {features?.modal_blurred_info.text}
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <Typography as="h6" weight="bold" variant="3xl" className={cn(titleStyles({ subscriptionView }))}>
              {selectedSubscription?.isTrial ? 'Unlock Trial Period' : currentContent.title[subscriptionView]}
            </Typography>
            {features?.discount_banner && currentContent.isDiscountBanner[subscriptionView] && <DiscountBanner />}
            <SubscriptionBenefitList
              subscriptionView={subscriptionView}
              benefits={currentContent.benefits}
              className={cn(
                'px-3',
                features?.discount_banner && currentContent.isDiscountBanner[subscriptionView] && 'grid grid-cols-2',
              )}
            />
          </div>
        )}
        <div className={cn('flex flex-col px-3 gap-2 max-h-[370px] overflow-hidden')}>
          <SubscriptionPriceList
            onSelectPrice={(pack) => setSelectedSubscription(pack)}
            packItemSlot={({ pack, isSelected }) => {
              return (
                <PriceRadioBtn
                  period={pack.period}
                  price={pack.originalPrice}
                  discount={
                    features?.subscription_discount ? features?.subscription_discount[pack.period] : pack.discount
                  }
                  initialAmount={pack.initialAmount}
                  currencyCode={features?.currency_code ?? CURRENCY_CODE.USD}
                  subscriptionView={subscriptionView}
                  isActive={isSelected}
                  isBest={pack.isPopular}
                  isTrialPeriod={pack.isTrial}
                />
              );
            }}
          />

          <CheckoutButton
            onSuccess={handleSuccessPayment}
            onFailed={handleFailedPayment}
            onCheckout={handleCheckoutPayment}
            size="lg"
            packId={selectedSubscription?.id ?? null}
          >
            {selectedSubscription?.isTrial ? 'Unlock Trial' : currentContent.button[subscriptionView]}{' '}
          </CheckoutButton>
          <span className="px-3 text-muted-foreground text-xs text-center">
            {features?.subscription_bottom_text
              ? currentContent.bottomText
              : 'You can cancel your subscription at any time'}
          </span>
        </div>
      </div>
    </>
  );
};

// <div
//   style={
//     {
//       '--cols': subscriptionList.length,
//     } as CSSProperties
//   }
//   className={cn(radioGroupStyles({subscriptionView: priceViewVariant}))}
// >
//   {subscriptionList.map((subscription) => {
//     const isActive = selectedSubscription?.id === subscription.id;
//     return (
//       <PriceRadioBtn
//         className="cursor-pointer"
//         onClick={() => onChangeSelectedSubscription(subscription.id)}
//         subscriptionView={priceViewVariant}
//         discount={
//           features?.subscription_discount
//             ? features?.subscription_discount[subscription.subscriptionPeriod]
//             : subscription.salePercentage
//         }
//         initialAmount={subscription.initialAmount}
//         validUntilPeriod={subscription.validUntilPeriod}
//         periodUntilUnit={subscription.periodUntilUnit}
//         currencyCode={currencyCode}
//         key={subscription.id}
//         isActive={isActive}
//         period={subscription.subscriptionPeriod as unknown as SUBSCRIPTION_INTERVAL}
//         price={subscription.price}
//         isBest={subscription.isBest}
//         isTrialPeriod={subscription.initialAmount === 0}
//       />
//     );
//   })}
// </div>
