import { GetSubscriptionPacksQuery, PaymentRepository } from '@repo/api/payment';
import type { InnerUseQueryOptions } from '@repo/common/types/react-query';
import { useQuery } from '@tanstack/react-query';
import { type SubscriptionPackAdaptEntity, subscriptionPackAdapter } from '../../../shared/adapters';

export const wasabiSubscriptionPacksQueryKey = (query: GetSubscriptionPacksQuery) => {
  return ['WASABI_SUBSCRIPTION_PACKS', 'SUBSCRIPTION_PACKS', query];
};
export const useGetWasabiSubscriptionPacks = (
  query: GetSubscriptionPacksQuery,
  options?: InnerUseQueryOptions<SubscriptionPackAdaptEntity[]>,
) => {
  return useQuery({
    ...options,
    queryKey: wasabiSubscriptionPacksQueryKey(query),
    queryFn: async () => {
      const response = await PaymentRepository.getWasabiSubscriptionPacks(query);
      return response.map(subscriptionPackAdapter);
    },
  });
};
