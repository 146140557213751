import { CSSProperties, type HTMLAttributes, useCallback } from 'react';

import { Layout, Timer } from './_ui';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';

import { buySubscriptionModal } from '@/features/modals';

import { useBrandFeatures } from '@/entities/brand';

import { renderFromTemplate } from '@/shared/libs/parsers';
import { cn, randomUuid } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Typography } from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLElement> {
  analyticData?: any;
  characterPicture?: string;
}

export const DiscountBanner = (props: Props) => {
  const { data: viewer } = useGetMeQuery();
  const { className, analyticData, style, characterPicture, ...restProps } = props;
  const features = useBrandFeatures();
  const bgUrl = features?.discount_banner?.background;

  const styles: CSSProperties = {
    ...(bgUrl && { backgroundImage: `url(${bgUrl})` }),
    ...style,
  };

  const templateDiscountLabel = renderFromTemplate(
    { vars: {}, value: features?.discount_banner?.label ?? '' },
    {
      bold: (el) => (
        <Typography as="span" key={randomUuid()} weight="extrabold" className="inline-block text-5xl px-1">
          {el}
        </Typography>
      ),
    },
  );

  const onOpenSubscription = useCallback(() => {
    buySubscriptionModal.open(
      {
        characterPicture,
        analyticData,
      },
      {
        props: {
          onMount: () => {
            Analytic.subscribeFromMainScreenClick();
          },
        },
      },
    );
  }, [characterPicture]);

  return (
    <Layout
      {...restProps}
      style={styles}
      className={cn('cursor-pointer ', className)}
      label={templateDiscountLabel}
      timer={
        <Timer
          createdAt={viewer?.created_at ?? ''}
          cycleDurationSeconds={features?.discount_banner?.cycle_duration_seconds ?? 86400}
        />
      }
      onClick={onOpenSubscription}
    />
  );
};
