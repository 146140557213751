import { useEffect } from 'react';

import { DIALOG_KEYS } from '@/core/constants';
import { paymentStorage } from '@repo/modules/entity/payment/services';
import { dialog } from '@repo/ui-kit/ui-dialogs';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

export const Route = createFileRoute('/(payment-status)/success')({
  component: Page,
});

function Page() {
  const navigate = useNavigate();
  useEffect(() => {
    dialog.alert({
      id: DIALOG_KEYS.PAYMENT_SUCCESS,
      titleSlot: 'Your payment was successful',
      cancelSlot: () => <></>,
      variant: 'success',
      closeDelayMs: 3000,
      props: {
        okProps: {
          variant: 'primary',
        },
        onUnmount: () => {
          paymentStorage.setStatusPayment('success');
          setTimeout(() => paymentStorage.setStatusPayment(null), 10);
          navigate({ to: '/' });
          window.close();
        },
      },
    });
  });

  return <></>;
}
