import { useMemo } from 'react';

import { queryClient } from '@/core/config/react-query';
import { LocalStorageKey, localStorageService } from '@/core/services';
import { routeTree } from '@/routeTree.gen';
import { useFeatures, useFeaturesBookStatus } from '@repo/common/services/features-book';
import type { ModuleConfigValue } from '@repo/modules/config';
import { ModuleConfigProvider } from '@repo/modules/config';
import { UiSplashScreen } from '@repo/ui-kit/ui-splash-screen';
import { RouterProvider, createRouter } from '@tanstack/react-router';

import { AppScripts } from '@/app/app-scripts';

import { useListenInternetConnection } from '@/features/listen-internet-connection';
import { useWatchAuthStorageUpdating } from '@/features/watch-auth-storage';

import { type BrandPayloadModel, useSelectBrandConfig } from '@/entities/brand';
import '@/entities/brand';
import { useRedtrackClickId } from '@/entities/redtrack';

import { SplashScreen } from '@/shared/ui';

export const router = createRouter({
  routeTree: routeTree,
  defaultPendingComponent: () => <SplashScreen spinnerKey="line-wobble" />,
  context: {
    queryClient: queryClient,
    isPassOnboarding: () => localStorageService.getItem(LocalStorageKey.IsPassOnboarding, false),
    brandConfig: {} as BrandPayloadModel & {
      version: number;
    },
    featuresGetter: {} as any,
  },
  defaultPreloadStaleTime: 0,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const AppRouter = () => {
  const brandConfig = useSelectBrandConfig();
  const features = useFeatures();
  const status = useFeaturesBookStatus();

  const context = {
    brandConfig: useMemo(() => ({ ...(brandConfig ?? {}) }), [brandConfig]),
    featuresGetter: <T,>(key: string, defaultValue?: T): T => {
      return features[key]?.value || defaultValue;
    },
  };

  const moduleConfig = useMemo(
    (): ModuleConfigValue => ({
      stripe: {
        addressElementOptions: {
          mode: 'billing',
          defaultValues: {
            address: {
              line1: 'auto',
              line2: 'auto',
              city: 'auto',
              state: 'auto',
              postal_code: 'always',
              country: 'auto',
            },
          },
        },
        publicKey: brandConfig?.constants?.stripe_public_key ?? '',
        appearance: {
          theme: (brandConfig?.features as any)?.stripeTheme === 'stripe' ? 'stripe' : 'night',
        },
      },
    }),
    [brandConfig],
  );

  useListenInternetConnection();
  useWatchAuthStorageUpdating();
  useRedtrackClickId();

  if (status === 'loading' || status === 'idle') {
    return <UiSplashScreen />;
  }
  return (
    <AppScripts constants={context?.brandConfig?.constants as any}>
      <ModuleConfigProvider value={moduleConfig}>
        <RouterProvider router={router} context={context as any}></RouterProvider>
      </ModuleConfigProvider>
    </AppScripts>
  );
};
