import {
  type CreateWasabiPaymentIntentDTO,
  type CreateWasabiPaymentIntentResponse,
  PaymentRepository,
} from '@repo/api/payment';
import type { InnerUseMutationOptions } from '@repo/common/types/react-query';
import { extractCookieValue } from '@repo/common/utils/helpers';
import { useMutation } from '@tanstack/react-query';
import { CookiesDictionary } from '../../../shared/constants/cookies-dictionary';

export const useCreateWasabiIntent = (
  options?: InnerUseMutationOptions<CreateWasabiPaymentIntentResponse, unknown, CreateWasabiPaymentIntentDTO>,
) => {
  return useMutation({
    mutationFn: (value) => {
      const clickId = extractCookieValue(CookiesDictionary.RED_TRACK);

      return PaymentRepository.createWasabiIntent({
        rtkclickid_store: clickId ?? undefined,
        ...value,
      });
    },
    ...options,
  });
};
