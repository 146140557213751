import { ReactNode } from 'react';

import { FloatingContext, FloatingFocusManager, FloatingOverlay } from '@floating-ui/react';
import { cn } from '@repo/common/utils/component';
import { HTMLMotionProps, MotionProps, motion } from 'framer-motion';

export interface Props extends HTMLMotionProps<'div'> {
  id: string;
  context: FloatingContext;
  children: ReactNode;
  onExit?: () => void;
}

const motionProps: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    backdropFilter: 'blur(4px)',
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const FloatingWrapper = (props: Props) => {
  const { id, context, children, className, onExit, ...restProps } = props;
  return (
    <motion.div
      className={cn('ui-fixed ui-top-0 ui-left-0 ui-w-full ui-h-full ui-bg-[rgba(0,0,0,0.5)] ui-z-[1000]', className)}
      {...motionProps}
      {...restProps}
    >
      <FloatingOverlay lockScroll id={id} className="ui-z-[1000]" onClick={onExit} />
      <FloatingFocusManager context={context}>
        <>{children}</>
      </FloatingFocusManager>
    </motion.div>
  );
};
