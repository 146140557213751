import { Target, TargetAndTransition } from 'framer-motion';

const initialBackdrop: Target = {
  opacity: 0,
  backdropFilter: 'blur(0px)',
};

const animateBackdrop: TargetAndTransition = {
  opacity: 1,
  backdropFilter: 'blur(4px)',
  transition: {
    duration: 0.3,
    delayChildren: 0.2,
  },
};

const exitBackdrop: TargetAndTransition = {
  ...initialBackdrop,
};

export const backdrop = {
  initial: initialBackdrop,
  animate: animateBackdrop,
  exit: exitBackdrop,
};

const initialBody: Target = {
  y: 50,
  opacity: 0,
};

const animateBody: TargetAndTransition = {
  y: 0,
  opacity: 1,
  transition: {
    type: 'spring',
    mass: 0.5,
    stiffness: 100,
  },
};

const exitBody: TargetAndTransition = {
  ...initialBody,
};

export const body = {
  initial: initialBody,
  animate: animateBody,
  exit: exitBody,
};
