import type { ComponentType } from 'react';

import { dialog } from './_model';
import type { OpenAlertPayload } from './types';
import { DialogType } from './types';
import type { OpenDialogPayload } from './types';
import merge from 'lodash.merge';

type Payload = Omit<OpenDialogPayload, 'content' | 'id'>;

export const withDialog = <P extends object>(Component: ComponentType<P>, dialogKey: string, defPayload?: Payload) => {
  const open = (props: P, payload?: Payload) => {
    const resultPayload = merge({ id: dialogKey }, defPayload, payload);

    if (resultPayload?.type === DialogType.Modal || !resultPayload?.type) {
      return dialog.modal({
        ...resultPayload,
        content: <Component {...props} />,
      });
    }

    if (resultPayload?.type === DialogType.Alert) {
      return dialog.alert({
        ...resultPayload,
        content: <Component {...props} />,
      } as OpenAlertPayload);
    }

    return () => {
      dialog.close(dialogKey);
    };
  };

  const close = () => {
    dialog.close(dialogKey);
  };

  return { open, close };
};
