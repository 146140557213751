import type { ReactSlot } from '@repo/common/types/helpers';
import { cn } from '@repo/common/utils/component';
import { DialogType, withDialog } from '@repo/ui-kit/ui-dialogs';
import type { Benefit } from '../-ui/benefits-carousel';
import { ImageSection } from '../-ui/image-section';
import { BasePaywallLayoutDialog } from '../../../../entities/payment/ui/base-paywall-layout-dialog';
import { SubscriptionPriceListProps } from '../../subscription-price-list';
import type { BasePaywallProps } from '../_types';
import { RightSection } from './right-section';

export interface Props extends BasePaywallProps {
  characterImgSrc: string;

  bannerSlot?: ReactSlot;

  captionSlot?: ReactSlot;

  packItemSlot?: SubscriptionPriceListProps['packItemSlot'];

  benefits?: Benefit[];
}

const KEY = 'alternative-monetization-paywall';

export const AlternativeMonetization = (props: Props) => {
  const { characterImgSrc, ...restProps } = props;

  return (
    <BasePaywallLayoutDialog
      className={cn('tablet:mod-w-[740px] tablet:mod-h-[476px] mod-h-full')}
      leftSection={<ImageSection src={characterImgSrc}></ImageSection>}
      rightSection={<RightSection {...restProps} />}
    />
  );
};

export const alternativeMonetization = withDialog(AlternativeMonetization, KEY, {
  type: DialogType.Modal,
  props: {
    closeBtnProps: {
      placement: 'top-left',
      variant: 'secondary',
    },
  },
});
