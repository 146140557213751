import { useCallback } from 'react';
import { useIsomorphicEffect } from '@repo/common/hooks/use-isomorphic-effect';
import type { EventPlacementVariant } from '@repo/common/services/analytic';
import { EventPaymentType } from '@repo/common/services/analytic';
import type { Nullable } from '@repo/common/types/helpers';
import { useCreatePaymentGatewayIntent } from '../../../entities/payment/queries/use-create-payment-gateway-intent';
import type { IPaymentStorageValue } from '../../../entities/payment/services';
import { paymentStorage } from '../../../entities/payment/services';

type Payload = {
  packId: Nullable<string>;

  onSuccess?: () => void;

  onFailed?: () => void;

  paymentType?: EventPaymentType;

  placement?: EventPlacementVariant;

  onStartProcess?: () => void;

  onEndProcess?: () => void;
};

export const usePaymentGatewayCheckout = (payload: Payload) => {
  const { packId, onSuccess, onFailed, onEndProcess, onStartProcess } = payload;

  const createPaymentIntent = useCreatePaymentGatewayIntent();

  const onCheckout = useCallback(
    async (email: string) => {
      if (!packId) return;
      onStartProcess?.();
      try {
        const response = await createPaymentIntent.mutateAsync({ product_id: packId, email });

        if (!response.order_url) return;

        setTimeout(() => {
          window.open(response.order_url, '_blank');
        });
      } catch (e) {
      } finally {
        onEndProcess?.();
      }
    },
    [packId, createPaymentIntent, onSuccess, onFailed],
  );

  const onStatusChangeListener = (value: Partial<IPaymentStorageValue>) => {
    if (value?.epochStatusPayment === 'success') {
      onSuccess?.();
      paymentStorage.setStatusPayment(null);
      return;
    }
    if (value?.epochStatusPayment === 'fail') {
      onFailed?.();
      paymentStorage.setStatusPayment(null);
    }
  };

  useIsomorphicEffect(() => {
    const disposer = paymentStorage.onChangeStorage(onStatusChangeListener);

    return () => {
      disposer();
    };
  }, []);

  return {
    onCheckout,
    isPending: createPaymentIntent.isPending,
  };
};
