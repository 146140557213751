import { SubscriptionTier } from '@/core/constants';
import { cn } from '@repo/common/utils/component';
import { DialogType, useUiDialogContext, withDialog } from '@repo/ui-kit/ui-dialogs';

import { BuySubscription } from '@/features/modals/components/buy-subscription';

import { useBrandFeatures } from '@/entities/brand';

import { ModalRootProps } from '@/shared/ui';

const KEY = 'buy-subscription';

export interface Props extends ModalRootProps {
  imgSrc?: string;

  characterPicture?: string;

  subscriptionVariant?: SubscriptionTier;

  analyticData?: any;

  onSuccessPayment?: () => void;
}

export const BuySubscriptionModal = (props: Props) => {
  const { subscriptionVariant, bodyClassName, analyticData, characterPicture, onSuccessPayment, imgSrc } = props;

  const dialogContext = useUiDialogContext();

  const features = useBrandFeatures();

  const handleSuccessPayment = () => {
    dialogContext.onClose();
    onSuccessPayment?.();
  };

  return (
    <div
      className={cn(
        'relative flex flex-col sm:flex-row h-full sm:h-[454px] sm:w-[740px] w-full ',
        {
          'sm:h-[506px]': features?.discount_banner,
        },
        bodyClassName,
      )}
    >
      <BuySubscription
        characterPicture={characterPicture}
        subscriptionVariant={subscriptionVariant}
        analyticData={analyticData}
        onSuccessPayment={handleSuccessPayment}
        imgSrc={imgSrc}
      />
    </div>
    // <Modal.Root
    //   onEventClose={handleCloseEvent}
    //   bodyClassName={cn(
    //     'sm:h-[454px] sm:max-w-[740px] w-full h-full',
    //     features?.discount_banner && 'sm:h-[506px]',
    //     bodyClassName,
    //   )}
    //   {...restProps}
    // >
    //   <Modal.Content className="flex flex-col sm:flex-row h-full">
    //     <Modal.CloseBtn onEventClose={handleCloseEvent} className="absolute top-4 left-4" />
    //     <BuySubscription
    //       characterPicture={characterPicture}
    //       subscriptionVariant={subscriptionVariant}
    //       analyticData={analyticData}
    //       onSuccessPayment={onSuccessPayment}
    //       imgSrc={imgSrc}
    //     />
    //   </Modal.Content>
    // </Modal.Root>
  );
};

export const buySubscriptionModal = withDialog(BuySubscriptionModal, KEY, {
  type: DialogType.Modal,
});
