import { Controller, FieldValues } from 'react-hook-form';

import { UiInput, UiInputProps } from '../../fields/ui-input';
import { FormControlProps } from '../_types';

export interface Props extends UiInputProps {}

export const UiFormInput = <T extends FieldValues>(props: Props & FormControlProps<T>) => {
  const { name, control, ...restProps } = props;
  return <Controller name={name} control={control} render={({ field }) => <UiInput {...restProps} {...field} />} />;
};
