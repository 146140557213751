import {
  type CreatePaymentGatewayPaymentIntentDTO,
  type CreatePaymentGatewayPaymentIntentResponse,
  PaymentRepository,
} from '@repo/api/payment';
import type { InnerUseMutationOptions } from '@repo/common/types/react-query';
import { useMutation } from '@tanstack/react-query';

export const useCreatePaymentGatewayIntent = (
  options?: InnerUseMutationOptions<
    CreatePaymentGatewayPaymentIntentResponse,
    unknown,
    CreatePaymentGatewayPaymentIntentDTO
  >,
) => {
  return useMutation({
    mutationFn: PaymentRepository.createPaymentGatewayIntent,
    ...options,
  });
};
