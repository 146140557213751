import { MouseEventHandler } from 'react';

import { Props as BuyCreditsModalProps, buySubscriptionModal } from './BuySubscriptionModal';
import Benefit1 from '@/assets/images/benefit-1.webp';
import Benefit2 from '@/assets/images/benefit-2.webp';
import Benefit3 from '@/assets/images/benefit-3.webp';
import Benefit4 from '@/assets/images/benefit-4.webp';
import { SubscriptionTier } from '@/core/constants';
import {
  AuthFlowVariantFlag,
  SubscriptionPaywallVariant,
  useGlobalFeatureFlagContext,
} from '@repo/common/services/features-book';
import { useGetMeQuery, useIsSubscribed } from '@repo/modules/entity/user-queries';
import { subscriptionPaywallDialog } from '@repo/modules/subscription-paywall-dialog';

import { DiscountBanner } from '@/features/discount-banner';
import { authModalAction } from '@/features/modals';

import { useTestGroupPaywallGifEnabled } from '@/entities/ab_test';
import { authStorageGetters } from '@/entities/auth';
import { useBrandFeatures } from '@/entities/brand';
import { usePaymentAnalytic } from '@/entities/payment/hooks/use-payment-analytic';
import { PriceRadioBtn } from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';
import { Analytic } from '@/shared/services/analytic';
import { Button, ButtonProps, Icon } from '@/shared/ui';

export interface Props extends ButtonProps {
  subscriptionVariant?: BuyCreditsModalProps['subscriptionVariant'];

  placementScreen?: string;

  label?: string;

  analyticData?: BuyCreditsModalProps['analyticData'];
}

export const BuySubscriptionModalTrigger = (props: Props) => {
  const { subscriptionVariant, label = 'Subscribe', analyticData, onClick, ...restProps } = props;

  const me = useGetMeQuery();

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const withSubscription = useIsSubscribed();

  const { subsPaywallVariant, subsPriceVariant, authFlowVariant } = useGlobalFeatureFlagContext();

  const withAuth = AuthFlowVariantFlag.AUTH_BEFORE_ACTION === authFlowVariant;

  const features = useBrandFeatures();

  const isGifEnabled = useTestGroupPaywallGifEnabled(me?.data?.split_test_groups) && features?.ab_test_gif_on_paywall;

  const paymentAnalytic = usePaymentAnalytic();

  const onDefaultPaywall = () => {
    buySubscriptionModal.open(
      {
        subscriptionVariant,
        analyticData,
      },
      {
        props: {
          onExit: () => {
            Analytic.paywallSkipClick(analyticData);
          },
        },
      },
    );
  };

  const paywallPicture = () => {
    if (subscriptionVariant === SubscriptionTier.PRO) {
      return isGifEnabled ? features?.subscription_gif?.pro : features?.subscription_image?.pro;
    }
    return isGifEnabled ? features?.subscription_gif?.pro : features?.subscription_image?.pro_plus;
  };

  const onPaywall1 = () => {
    subscriptionPaywallDialog.alternativeMonetization.open({
      characterImgSrc: paywallPicture() ?? '',
      bannerSlot: features?.discount_banner ? <DiscountBanner className="rounded-[inherit]" /> : null,

      captionSlot: () => null,
      packItemSlot: ({ pack, isSelected }) => {
        return (
          <PriceRadioBtn
            period={pack.period}
            price={pack.originalPrice}
            discount={features?.subscription_discount ? features?.subscription_discount[pack.period] : pack.discount}
            currencyCode={features?.currency_code ?? CURRENCY_CODE.USD}
            subscriptionView={subsPriceVariant as any}
            isActive={isSelected}
            isBest={pack.isPopular}
          />
        );
      },
      benefits: [
        { key: 1, title: 'Unlock unlimited chats and messages!', icon: Benefit1 },
        { key: 2, title: 'Create your own models', icon: Benefit2 },
        { key: 3, title: 'Get 150 free coins', icon: Benefit3 },
        { key: 4, title: 'Unlock all hot content', icon: Benefit4 },
      ],
      onSuccess: (pack) => {
        if (!pack) return;
        paymentAnalytic.success({
          pack,
          type: 'subscription',
        });
      },
      onFailed: (pack) => {
        if (!pack) return;
        paymentAnalytic.failed({
          pack,
          type: 'subscription',
        });
      },
      onCheckout: (pack) => {
        if (!pack) return;
        paymentAnalytic.startCheckout({
          pack,
          type: 'subscription',
        });
      },
    });
  };

  const onOpenPaywall = () => {
    switch (subsPaywallVariant) {
      case SubscriptionPaywallVariant.DEFAULT:
        onDefaultPaywall();
        break;
      case SubscriptionPaywallVariant.PAYWALL_1:
        onPaywall1();
        break;
    }
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (withAuth && !withSubscription && !isAuthenticated) {
      authModalAction.open(
        {
          onSuccess: (user) => {
            if (!user.is_subscribed) onOpenPaywall();
          },
        },
        {
          afterClose: onOpenPaywall,
        },
      );
    } else {
      onOpenPaywall();
    }
    onClick?.(event);
  };

  const icon = features?.subscription_btn_icon?.icon ?? null;

  return (
    <Button type="button" w="auto" onClick={handleClick} {...restProps}>
      <span className="flex items-center gap-1">
        {icon && <Icon k={icon} className="text-3xl" />} {label}
      </span>
    </Button>
  );
};
