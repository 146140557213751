import { useState } from 'react';
import type { Nullable } from '@repo/common/types/helpers';
import type { SubscriptionPackAdaptEntity } from '../../../../shared/adapters';

export const useSelectPack = () => {
  const [pack, setPack] = useState<Nullable<SubscriptionPackAdaptEntity>>(null);

  const onSelectPack = (pack: SubscriptionPackAdaptEntity) => {
    setPack(pack);
  };

  return [pack, onSelectPack] as const;
};
