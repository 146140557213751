import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';

import { Slot, Slottable } from '@radix-ui/react-slot';
import type { ValueByMedia } from '@repo/common/hooks/use-value-by-media';
import { useValueByMedia } from '@repo/common/hooks/use-value-by-media';
import { cn } from '@repo/common/utils/component';
import { VariantProps, cva } from 'class-variance-authority';

const uiButton = cva(
  `
    ui-kit-btn
    ui-relative ui-flex ui-gap-2 ui-items-center ui-justify-center ui-text-center
    ui-transition-all ui-duration-300 ui-cursor-pointer
    disabled:ui-cursor-not-allowed disabled:ui-opacity-50 disabled:ui-pointer-events-none
  `,
  {
    variants: {
      variant: {
        primary: 'ui-kit-btn-primary',
        secondary: 'ui-kit-btn-secondary',
        tertiary: 'ui-kit-btn-tertiary',
        outline: 'ui-kit-btn-outline',
        'outline-secondary': 'ui-kit-btn-outline-secondary',
        ghost: 'ui-kit-btn-ghost',
        light: 'ui-kit-btn-light',
        action: 'ui-kit-btn-action',
        black: 'ui-kit-btn-black',
        grey: 'ui-kit-btn-grey',
        green: 'ui-kit-btn-green',
        clean: 'ui-kit-btn-clean',
      },
      w: {
        full: 'ui-w-full',
        auto: 'ui-w-auto',
        fit: 'ui-w-fit',
        none: null,
      },
      size: {
        xs: 'ui-kit-btn-xs',
        sm: 'ui-kit-btn-sm',
        md: 'ui-kit-btn-md',
        lg: 'ui-kit-btn-lg',
        xl: 'ui-kit-btn-xl',
        icon: 'ui-kit-btn-icon',
        'icon-xs': 'ui-kit-btn-icon-xs',
        'icon-sm': 'ui-kit-btn-icon-sm',
        'icon-lg': 'ui-kit-btn-icon-lg',
      },
      rounded: {
        xs: 'ui-kit-btn-rounded-xs',
        sm: 'ui-kit-btn-rounded-sm',
        md: 'ui-kit-btn-rounded-md',
        lg: 'ui-kit-btn-rounded-lg',
        full: 'ui-rounded-full',
        none: null,
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      rounded: 'md',
      w: 'full',
    },
  },
);

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, Omit<VariantProps<typeof uiButton>, 'size'> {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  asChild?: boolean;
  size?: ValueByMedia<VariantProps<typeof uiButton>['size']>;
}

export const UiButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    asChild,
    leftElement,
    rightElement,
    children,
    variant = 'primary',
    rounded = 'md',
    size,
    w = 'full',
    className,
    ...restProps
  } = props;

  const Comp = asChild ? Slot : 'button';

  const _size = useValueByMedia(size, 'md');

  return (
    <Comp ref={ref} className={cn(uiButton({ variant, rounded, size: _size, w }), className)} {...restProps}>
      {leftElement}
      <Slottable>{children}</Slottable>
      {rightElement}
    </Comp>
  );
});
