import { Highlight, HighlightProps } from '@ark-ui/react';
import { cn } from '@repo/common/utils/component';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const uiHighlight = cva('ui-highlight', {
  variants: {
    variant: {
      primary: 'ui-highlight-primary',
      secondary: 'ui-highlight-secondary',
    },
    size: {
      sm: 'ui-highlight-sm',
      md: 'ui-highlight-md',
      lg: 'ui-highlight-lg',
    },

    rounded: {
      sm: 'ui-highlight-rounded-sm',
      md: 'ui-highlight-rounded-md',
      lg: 'ui-highlight-rounded-lg',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'sm',
    rounded: 'md',
  },
});

export interface Props extends HighlightProps, VariantProps<typeof uiHighlight> {}

export const UiHighlight = (props: Props) => {
  const { className, rounded = 'md', size = 'sm', variant = 'primary', ...restProps } = props;

  return <Highlight className={cn(uiHighlight({ rounded, size, variant }), className)} {...restProps} />;
};
