import { useCallback, useEffect, useRef, useState } from 'react';
import { SubscriptionPriceVariant, useGlobalFeatureFlagContext } from '@repo/common/services/features-book';
import type { Nullable } from '@repo/common/types/helpers';
import { cn, renderSlot } from '@repo/common/utils/component';
import { CarouselApi, Mousewheel, Scrollbar, UiCarousel } from '@repo/ui-kit/ui-carousel';
import type { SubscriptionPackAdaptEntity } from '../../../shared/adapters';
import type { GeneralSubscriptionPriceListProps } from './_types';

export interface Props extends Omit<GeneralSubscriptionPriceListProps, 'packType'> {
  packs: SubscriptionPackAdaptEntity[];
}

export const BaseList = (props: Props) => {
  const { className, onSelectPrice, disabled, packs, packItemSlot, slideClass, ...restProps } = props;

  const { subsPriceVariant } = useGlobalFeatureFlagContext();

  const carouselApi = useRef<Nullable<CarouselApi>>(null);

  const contentNodes = useRef<HTMLDivElement[]>([]);

  const [selectedPrice, setSelectedPrice] = useState<Nullable<SubscriptionPackAdaptEntity>>(null);

  const onPackSelect = useCallback((pack: SubscriptionPackAdaptEntity) => {
    setSelectedPrice(pack);
    onSelectPrice?.(pack);
  }, []);

  const setupContentNode = useCallback((node: Nullable<HTMLDivElement>) => {
    if (!node) return;
    contentNodes.current.push(node);
  }, []);

  useEffect(() => {
    if (!packs.length) return;
    const bestPack = packs.find((pack) => pack.isPopular) ?? packs[0];
    onPackSelect(bestPack);
  }, [packs]);

  return (
    <UiCarousel
      data-disabled={disabled}
      className={cn('group mod-pt-2.5', className)}
      wrapperTag="ul"
      centeredSlidesBounds
      onSwiper={(carousel) => (carouselApi.current = carousel)}
      direction={subsPriceVariant === SubscriptionPriceVariant.DEFAULT ? 'vertical' : 'horizontal'}
      enabled={!disabled}
      mousewheel
      scrollbar={{
        hide: true,
      }}
      modules={[Mousewheel, Scrollbar]}
      slidesPerView={subsPriceVariant === SubscriptionPriceVariant.DEFAULT ? 'auto' : 3}
      spaceBetween={8}
      {...restProps}
    >
      {packs.map((pack) => {
        return (
          <UiCarousel.Slide
            tag="li"
            className={cn(
              '!mod-h-auto mod-cursor-pointer',
              { 'mod-opacity-50 mod-cursor-not-allowed mod-pointer-events-none': disabled },
              slideClass,
            )}
            onClick={() => onPackSelect(pack)}
            key={pack.id}
          >
            <div ref={setupContentNode}>
              {packItemSlot ? renderSlot(packItemSlot, [{ pack, isSelected: pack.id === selectedPrice?.id }]) : null}
            </div>
          </UiCarousel.Slide>
        );
      })}
    </UiCarousel>
  );
};
