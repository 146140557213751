import { useGetEpochSubscriptionPacks } from '../../../entities/payment/queries';
import type { GeneralSubscriptionPriceListProps } from './_types';
import { BaseList } from './base-list';

export interface Props extends GeneralSubscriptionPriceListProps {}

export const Epoch = (props: Props) => {
  const { packType, ...restProps } = props;

  const packs = useGetEpochSubscriptionPacks({
    lookup_keys: packType,
  });

  return <BaseList packs={packs?.data ?? []} {...restProps} />;
};
