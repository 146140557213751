import type { FormEventHandler } from 'react';
import { useCallback, useState } from 'react';
import { useUiDialogContext } from '@repo/ui-kit/ui-dialogs';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

type Payload = {
  onSuccess?: () => void;
  onFailed?: (error?: string) => void;
};

export const useStripeCheckout = (payload: Payload) => {
  const { onSuccess, onFailed } = payload;

  const [isReadyElements, setReadyElements] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isCompleteForm, setCompleteForm] = useState(false);

  const dialogContext = useUiDialogContext();

  const elements = useElements();
  const stripe = useStripe();

  const onReadyStripeElements = useCallback(() => {
    setReadyElements(true);
  }, []);

  const onChangeStripeElements = useCallback((event: StripePaymentElementChangeEvent) => {
    setCompleteForm(event.complete);
  }, []);

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.href,
      },
    });

    setProcessing(false);

    if (error) return onFailed?.(error.message ?? 'Payment error');

    dialogContext.onClose();
    onSuccess?.();
  };

  const isDisabledSubmit = !isReadyElements || !isCompleteForm || isProcessing;

  return { isDisabledSubmit, onSubmit, onReadyStripeElements, onChangeStripeElements, isProcessing };
};
