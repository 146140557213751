import { type MouseEventHandler, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Nullable } from '@repo/common/types/helpers';
import type { PaymentStatusActions } from '@repo/common/types/props';
import { UiButton } from '@repo/ui-kit/ui-button';
import { withDialog } from '@repo/ui-kit/ui-dialogs';
import { UiFieldBox } from '@repo/ui-kit/ui-field-box';
import { UiFormInput } from '@repo/ui-kit/ui-form-input';
import { z } from 'zod';
import { usePaymentGatewayCheckout } from '../use-payment-gateway-checkout';
import type { GeneralCheckoutButtonProps } from './_types';
import { BaseButton } from './base-button';

const schema = z.object({
  email: z.string().email(),
});

type FormValues = z.infer<typeof schema>;

const KEY = 'payment-gateway';

const EmailDialog = withDialog(
  ({
    packId,
    onCheckout: _onCheckout,
    onEndProcess,
    onStartProcess,
    ...restProps
  }: {
    packId: Nullable<string>;
    onStartProcess?: () => void;
    onEndProcess?: () => void;
  } & PaymentStatusActions) => {
    const form = useForm<FormValues>({ resolver: zodResolver(schema) });

    const { onCheckout, isPending } = usePaymentGatewayCheckout({
      packId,
      onEndProcess,
      onStartProcess,
      ...restProps,
    });

    const onSubmit = form.handleSubmit(async (data) => {
      _onCheckout?.();
      onCheckout(data.email);
    });

    return (
      <div className="mod-px-4 mod-py-4 tablet:mod-w-[450px] mod-h-full tablet:mod-h-auto mod-flex mod-flex-col">
        <form onSubmit={onSubmit} className="mod-flex mod-flex-col mod-justify-between mod-h-full mod-gap-6">
          <UiFieldBox label="Email" error={form.formState?.errors?.email?.message}>
            <UiFormInput control={form.control} name="email" />
          </UiFieldBox>
          <UiButton disabled={isPending} type="submit" size="lg">
            Continue
          </UiButton>
        </form>
      </div>
    );
  },
  KEY,
  {
    props: {
      placement: 'center',
    },
  },
);

export interface Props extends GeneralCheckoutButtonProps {}

export const PaymentGateway = (props: Props) => {
  const { packId, onClick, onCheckout, onSuccess, onFailed, onStartProcess, onEndProcess, ...restProps } = props;

  const onHandleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      EmailDialog.open({ packId, onCheckout, onSuccess, onFailed, onStartProcess, onEndProcess });
      onClick?.(e);
    },
    [onCheckout, onClick, onEndProcess, onFailed, onStartProcess, onSuccess, packId],
  );

  return <BaseButton onClick={onHandleClick} {...restProps} />;
};
