import { useFeatureGetter } from '../api';

const KEY = 'subscription_paywall_view';

export enum SubscriptionPriceVariant {
  DEFAULT,
  SQUARE,
}

export const useSubscriptionPriceVariantGetter = () => {
  return useFeatureGetter(KEY, SubscriptionPriceVariant.DEFAULT);
};
