import { PaymentRepository } from '@repo/api/payment';
import type { CreateStripeCustomerResponse } from '@repo/api/payment';
import type { InnerUseMutationOptions } from '@repo/common/types/react-query';
import { useMutation } from '@tanstack/react-query';

export const useCreateStripeCustomer = (options?: InnerUseMutationOptions<CreateStripeCustomerResponse>) => {
  return useMutation({
    mutationFn: () => PaymentRepository.createStripeCustomer(),
    ...options,
  });
};
