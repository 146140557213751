import { useCallback } from 'react';
import { useIsomorphicEffect } from '@repo/common/hooks/use-isomorphic-effect';
import type { EventPlacementVariant } from '@repo/common/services/analytic';
import { EventPaymentType } from '@repo/common/services/analytic';
import type { Nullable } from '@repo/common/types/helpers';
import { useCreateEpochPaymentIntent } from '../../../entities/payment/queries';
import type { IPaymentStorageValue } from '../../../entities/payment/services';
import { paymentStorage } from '../../../entities/payment/services';

type Payload = {
  packId: Nullable<string>;

  onSuccess?: () => void;

  onFailed?: () => void;

  paymentType?: EventPaymentType;

  placement?: EventPlacementVariant;

  onStartProcess?: () => void;

  onEndProcess?: () => void;
};

export const useEpochCheckout = (payload: Payload) => {
  const { packId, onSuccess, onFailed, onEndProcess, onStartProcess } = payload;

  const createPaymentIntent = useCreateEpochPaymentIntent();

  const onCheckout = useCallback(async () => {
    if (!packId) return;
    onStartProcess?.();
    try {
      const response = await createPaymentIntent.mutateAsync({ pack: packId });

      setTimeout(() => {
        window.open(response.redirectUrl, '_blank');
      });
    } catch (e) {
    } finally {
      onEndProcess?.();
    }
  }, [packId, createPaymentIntent, onSuccess, onFailed]);

  const onStatusChangeListener = (value: Partial<IPaymentStorageValue>) => {
    if (value?.epochStatusPayment === 'success') {
      onSuccess?.();
      paymentStorage.setEpochStatusPayment(null);
      return;
    }
    if (value?.epochStatusPayment === 'fail') {
      onFailed?.();
      paymentStorage.setEpochStatusPayment(null);
    }
  };

  useIsomorphicEffect(() => {
    const disposer = paymentStorage.onChangeStorage(onStatusChangeListener);

    return () => {
      disposer();
    };
  }, []);

  return {
    onCheckout,
    isPending: createPaymentIntent.isPending,
  };
};
