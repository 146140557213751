import { forwardRef } from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import { cn } from '@repo/common/utils/component';

export interface Props extends ComponentPropsWithoutRef<'div'> {}

export const BannerBox = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, ...restProps } = props;
  return (
    <div
      className={cn('mod-shrink-0 mod-overflow-hidden tablet:mod-rounded-xl mod-h-max', className)}
      ref={ref}
      {...restProps}
    />
  );
});
