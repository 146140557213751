import { useFeatureGetter } from '../api';

const KEY = 'subscription-paywall-variant';

export enum SubscriptionPaywallVariant {
  DEFAULT,
  PAYWALL_1,
}

export const useSubscriptionPaywallVariantGetter = () => {
  return useFeatureGetter(KEY, SubscriptionPaywallVariant.DEFAULT);
};
