import { useLayoutEffect } from 'react';

import { useDialog } from '../../_hooks';
import { dialog } from '../../_model';
import { ModalProps } from '../../types';
import { DialogContent } from '../dialog-content';
import { FloatingWrapper } from '../floating-wrapper';
import { cn } from '@repo/common/utils/component';

export interface Props extends ModalProps {}

export const Modal = (props: Props) => {
  const {
    id,
    closeOnEsc,
    className,
    classNames,
    children,
    closeBtnProps,
    showCloseBtn = true,
    onClose,
    onMount,
    onUnmount,
    onExit,
    ...restProps
  } = props;

  const onInternalClose = () => {
    if (onClose) {
      onClose(id);
    } else {
      dialog.close(id);
    }
  };

  const { context, refs, getFloatingProps } = useDialog({
    isOpen: true,
    id,
    closeOnEsc,
    onClose: onInternalClose,
  });

  useLayoutEffect(() => {
    onMount?.();

    return () => {
      onUnmount?.();
    };
  }, []);

  return (
    <FloatingWrapper onExit={onExit} id={id} context={context} className={classNames?.overlay}>
      <DialogContent
        onExit={onExit}
        innerRef={refs.setFloating}
        closeBtnProps={closeBtnProps}
        showCloseBtn={showCloseBtn}
        onClose={onInternalClose}
        {...getFloatingProps({
          className: cn('ui-z-[1001]', classNames?.root, className),
          ...restProps,
        })}
      >
        {children}
      </DialogContent>
    </FloatingWrapper>
  );
};
