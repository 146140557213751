import { HTMLAttributes, ReactNode, forwardRef, useCallback, useMemo, useState } from 'react';

import { UiError } from '../../ui-error';
import { UiLabel } from '../../ui-label';
import { ContextValue, FieldBoxProvider } from './field-box.provider';
import { ClassNames } from '@repo/common/types/helpers';
import { randomId } from '@repo/common/utils/base';
import { cn, isPrimitiveNode } from '@repo/common/utils/component';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: ReactNode;

  error?: ReactNode;

  disabled?: boolean;

  classNames?: ClassNames<'root' | 'label' | 'error'>;
}

export const UiFieldBox = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, disabled = false, classNames, id, children, label, error, ...restProps } = props;

  const [innerDisabled, setInnerDisabled] = useState<boolean>(disabled);
  const [innerId, setInnerId] = useState<string>(() => {
    if (id) {
      return id;
    }
    return randomId();
  });

  const onSetIdInside = useCallback((id: string) => {
    setInnerId(id);
  }, []);

  const onSetDisabledInside = useCallback((disabled: boolean) => {
    setInnerDisabled(disabled);
  }, []);

  const contextValue: ContextValue = useMemo(
    () => ({
      hasError: Boolean(error),
      fieldId: innerId,
      setIdInside: onSetIdInside,
      disabled: innerDisabled,
      setDisabled: onSetDisabledInside,
    }),
    [error, innerId, innerDisabled],
  );

  const labelNode = useMemo(() => {
    if (!label) return null;

    return (
      <UiLabel htmlFor={innerId} className={cn('ui-pl-0', classNames?.label)}>
        {label}
      </UiLabel>
    );
  }, [label, innerId, classNames?.label]);

  const errorNode = useMemo(() => {
    if (!error) return null;
    if (isPrimitiveNode(error)) {
      return <UiError className={cn('ui-pl-2', classNames?.error)}>{error}</UiError>;
    }
    return error;
  }, [error, classNames?.error]);

  return (
    <FieldBoxProvider value={contextValue}>
      <div
        ref={ref}
        data-disabled={disabled}
        className={cn('ui-w-full ui-flex ui-flex-col ui-gap-1.5', classNames?.root, className)}
        {...restProps}
      >
        {labelNode}
        {children}
        {errorNode}
      </div>
    </FieldBoxProvider>
  );
});
