import { Fragment, InputHTMLAttributes, ReactNode, forwardRef, useMemo } from 'react';

import { useFieldBoxContext } from '../ui-field-box/field-box.provider';
import { useIsomorphicEffect } from '@repo/common/hooks/use-isomorphic-effect';
import { cn } from '@repo/common/utils/component';
import { VariantProps, cva } from 'class-variance-authority';

const uiInput = cva(
  'ui-input ui-w-full ui-transition-all disabled:ui-opacity-60 focus-visible:!ui-outline-none focus:!ui-outline-none ui-p-3',
  {
    variants: {
      rounded: {
        sm: 'ui-rounded-sm ui-input-rounded-sm',
        md: 'ui-rounded-md ui-input-rounded-md',
        lg: 'ui-rounded-lg ui-input-rounded-md',
        xl: 'ui-rounded-xl ui-input-rounded-lg',
      },
    },
    defaultVariants: {
      rounded: 'md',
    },
  },
);

export interface Props extends InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof uiInput> {
  chevron?: ReactNode;
}

export const UiInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { chevron, disabled, id, name, className, rounded = 'md' } = props;

  const fieldContext = useFieldBoxContext();

  const Wrapper = chevron ? 'div' : Fragment;
  const wrapperProps = chevron ? { className: 'ui-relative ui-input-wrapper' } : {};

  const innerId = useMemo(() => {
    if (id) {
      return id;
    }
    if (name) {
      return name;
    }
    return undefined;
  }, [id, name]);

  const isDisabled = fieldContext?.disabled !== undefined ? fieldContext?.disabled : disabled;

  useIsomorphicEffect(() => {
    if (fieldContext?.setIdInside && innerId) {
      fieldContext.setIdInside(innerId);
    }
    if (fieldContext?.setDisabled && disabled !== undefined) {
      fieldContext.setDisabled(disabled);
    }
  }, [innerId]);

  return (
    <Wrapper {...wrapperProps}>
      <input
        disabled={isDisabled}
        id={fieldContext?.fieldId || id}
        ref={ref}
        autoComplete="off"
        className={cn(
          uiInput({ rounded }),
          {
            'ui-input-chevron': chevron,
            'ui-input-error': fieldContext?.hasError,
            'ui-input-disabled': isDisabled,
          },
          className,
        )}
        {...props}
      />
      {chevron && (
        <div className="ui-absolute ui-input-chevron ui-flex ui-items-center ui-top-1/2 -ui-translate-y-1/2 ui-right-0 ui-transform -ui-translate-x-3  ui-text-[24px] ui-size-6">
          {chevron}
        </div>
      )}
    </Wrapper>
  );
});
