import { useCallback, useLayoutEffect, useRef } from 'react';

import { BaseDialogProps, useDialogContext } from '../../libs';
import { backdrop, body } from './dialog-layout.config';
import './styles.css';
import { motion } from 'framer-motion';

import { KeyboardMap } from '@/shared/constants';
import { cn } from '@/shared/libs/utils';

export interface Props extends BaseDialogProps {
  bodyClassName?: string;

  fullWidth?: boolean;

  fullScreen?: boolean;

  alwaysCentered?: boolean;

  closeOnEscape?: boolean;

  onEventClose?: () => void;
}

export const DialogLayout = (props: Props) => {
  const {
    className,
    children,
    bodyClassName,
    centered = true,
    alwaysCentered = false,
    closeOnClickOutside = true,
    fullWidth = false,
    closeOnEscape = true,
    fullScreen = false,
    onEventClose,
    ...restProps
  } = props;

  const { dialogKey, isHidden, onClose } = useDialogContext();

  const modalRef = useRef<HTMLDivElement>(null);

  const handlePressEscape = (event: KeyboardEvent) => {
    if (event.key === KeyboardMap.Escape && closeOnEscape) {
      onEventClose?.();
      onClose();
    }
  };

  const handleTabKeyPress = (event: KeyboardEvent) => {
    const modalElement = modalRef?.current;

    const focusableElements = modalElement?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    const firstElement = focusableElements?.[0] as HTMLElement;
    const lastElement = focusableElements?.[focusableElements.length - 1] as HTMLElement;

    if (event.key === KeyboardMap.Tab) {
      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      } else if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    }
  };

  const handleClickOutside = useCallback(() => {
    if (closeOnClickOutside) {
      onEventClose?.();
      onClose();
    }
  }, [closeOnClickOutside, onEventClose, onClose]);

  useLayoutEffect(() => {
    const modalElement = modalRef?.current;

    modalElement?.focus({ preventScroll: true });

    modalElement?.addEventListener('keydown', handleTabKeyPress);
    modalElement?.addEventListener('keydown', handlePressEscape);

    return () => {
      modalElement?.removeEventListener('keydown', handleTabKeyPress);
      modalElement?.removeEventListener('keydown', handlePressEscape);
    };
  }, []);

  return (
    <motion.div
      key={dialogKey}
      className={cn(
        'fixed flex flex-col mx-auto top-0 inset-x-0 w-full h-full pt-6 sm:px-1 transition-visibility',
        {
          'items-center': centered,
          'opacity-0 invisible': isHidden,
          'justify-end sm:justify-center': !alwaysCentered,
          'justify-center': alwaysCentered,
          'pt-0': fullScreen,
        },
        className,
      )}
      {...(restProps as any)}
    >
      <motion.div
        className="fixed z-modal-backdrop inset-0 bg-black/50 backdrop-blur-sm"
        onClick={handleClickOutside}
        {...backdrop}
      />

      <motion.div
        tabIndex={0}
        ref={modalRef}
        initial={body.initial}
        animate={body.animate}
        exit={body.exit}
        className={cn(
          'dialog-layout-content relative z-modal focus-visible:ring-0 outline-none focus-visible:ring-offset-0 flex flex-col rounded-t-modal sm:rounded-modal overflow-hidden',
          { 'w-full': fullWidth, 'rounded-modal': alwaysCentered, 'w-full h-full': fullScreen },
          bodyClassName,
        )}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};
