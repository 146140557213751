import { createOptionalContext } from '@repo/common/utils/context';

export interface ContextValue {
  disabled: boolean;

  hasError: boolean;

  fieldId: string;

  setIdInside: (id: string) => void;

  setDisabled: (disabled: boolean) => void;
}

const [Provider, useFieldBoxContext] = createOptionalContext<ContextValue>();

export { Provider as FieldBoxProvider, useFieldBoxContext };
