import { useMemo } from 'react';
import { UiButton } from '@repo/ui-kit/ui-button';
import { DialogType, withDialog } from '@repo/ui-kit/ui-dialogs';
import { UiAnimatedIcon } from '@repo/ui-kit/ui-icon';
import { UiScrollArea } from '@repo/ui-kit/ui-scroll-area';
import { AddressElement, Elements, PaymentElement } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { useModuleConfigContext } from '../../../shared/config';
import { useStripeCheckout } from './use-stripe-checkout';

const stripePromise = async (publicKey: string) => {
  const { loadStripe } = await import('@stripe/stripe-js/pure');
  return loadStripe(publicKey);
};

export interface Props {
  clientSecret: string;

  onSuccess?: () => void;

  onFailed?: (error?: string) => void;
}

const KEY = 'stripe-checkout-dialog';

const _StripeCheckoutDialog = (props: Omit<Props, 'clientSecret'>) => {
  const { onFailed, onSuccess } = props;

  const { stripe } = useModuleConfigContext();

  const stripeCheckout = useStripeCheckout({
    onSuccess,
    onFailed,
  });

  const renderAddressElements = () => {
    if (!stripe?.addressElementOptions || !Object.keys(stripe?.addressElementOptions).length) return null;

    return <AddressElement options={stripe.addressElementOptions} />;
  };

  return (
    <div className="mod-relative mod-h-full mod-flex mod-flex-col  mod-py-4 tablet:mod-max-w-[512px] tablet:mod-w-[90vw] tablet:mod-max-h-[512px]">
      <UiScrollArea.Root className="mod-h-full mod-grow mod-px-3">
        <UiScrollArea.Viewport>
          <form onSubmit={stripeCheckout.onSubmit}>
            <PaymentElement
              onReady={stripeCheckout.onReadyStripeElements}
              onChange={stripeCheckout.onChangeStripeElements}
            />

            {renderAddressElements()}
            <div className="mod-pt-3 mod-px-3 mod-grow mod-basis-[56px] mod-shrink-0">
              <UiButton
                type="submit"
                disabled={stripeCheckout.isDisabledSubmit}
                leftElement={stripeCheckout.isProcessing ? <UiAnimatedIcon k="spinner" /> : null}
              >
                Buy now
              </UiButton>
            </div>
          </form>
        </UiScrollArea.Viewport>
        <UiScrollArea.Scrollbar>
          <UiScrollArea.Thumb />
        </UiScrollArea.Scrollbar>
      </UiScrollArea.Root>
    </div>
  );
};

export const StripeCheckoutDialog = (props: Props) => {
  const { clientSecret, ...restProps } = props;

  const { stripe } = useModuleConfigContext();

  const { publicKey, ...restStripe } = stripe;

  const config = useMemo(
    (): StripeElementsOptions =>
      ({
        clientSecret,
        loader: 'always',
        ...restStripe,
      }) as StripeElementsOptions,
    [clientSecret, restStripe],
  );

  return (
    <Elements stripe={stripePromise(publicKey)} options={config}>
      <_StripeCheckoutDialog {...restProps} />
    </Elements>
  );
};

export const stripeCheckoutDialog = withDialog(StripeCheckoutDialog, KEY, {
  type: DialogType.Modal,
});
