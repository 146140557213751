import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_layout/create-ai')({
  beforeLoad: ({ context }) => {
    const { featuresGetter } = context;

    const createAi = featuresGetter('create-ai', { enabled: false });

    if (!createAi.enabled) {
      throw redirect({ to: '/' });
    }
  },
});
