import { PaymentRepository } from '@repo/api/payment';
import type { EpochCreatePaymentIntentDTO } from '@repo/api/payment';
import type { InnerUseMutationOptions } from '@repo/common/types/react-query';
import { extractCookieValue } from '@repo/common/utils/helpers';
import { useMutation } from '@tanstack/react-query';
import { CookiesDictionary } from '../../../shared/constants/cookies-dictionary';

export const useCreateEpochPaymentIntent = (
  options?: InnerUseMutationOptions<unknown, unknown, EpochCreatePaymentIntentDTO>,
) => {
  return useMutation({
    ...options,
    mutationFn: (value) => {
      const clickId = extractCookieValue(CookiesDictionary.RED_TRACK);

      return PaymentRepository.createEpochPaymentIntent({
        rtkclickid_store: clickId ?? undefined,
        ...value,
      });
    },
  });
};
