import type { ComponentPropsWithoutRef } from 'react';
import { cn } from '@repo/common/utils/component';

export interface Props extends ComponentPropsWithoutRef<'div'> {
  src: string;
  wrapperClass?: string;
}

export const ImageSection = (props: Props) => {
  const { className, children, style, src, wrapperClass, ...restProps } = props;

  const styles = {
    backgroundImage: `url(${src})`,
    ...style,
  };

  return (
    <div
      style={styles}
      className={cn(
        'mod-relative mod-size-full mod-bg-cover mod-bg-center mod-bg-no-repeat before:mod-fading-backdrop before:mod-h-2/3',
        className,
      )}
      {...restProps}
    >
      <div className={cn('mod-z-2 mod-size-full', wrapperClass)}>{children}</div>
    </div>
  );
};
