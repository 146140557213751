import { useCallback } from 'react';

import type { CreditPackAdaptedEntity, SubscriptionPackAdaptEntity } from '@repo/modules/api-adapters';

import { useBrandFeatures } from '@/entities/brand';

import { CURRENCY_CODE } from '@/shared/constants';
import { Analytic } from '@/shared/services/analytic';
import type { PlacementVariant, ViewerSubscription } from '@/shared/services/analytic/dto';

export const usePaymentAnalytic = (placement?: PlacementVariant) => {
  const features = useBrandFeatures();

  const currencyCode = features?.currency_code ?? CURRENCY_CODE.USD;

  const success = useCallback(
    ({
      pack,
      type = 'subscription',
    }: {
      pack: CreditPackAdaptedEntity | SubscriptionPackAdaptEntity;
      type?: ViewerSubscription;
    }) => {
      Analytic.purchaseSuccessful({
        item_id: pack.id,
        currency: currencyCode,
        price: pack.price,
        type: type,
        placement: placement ?? 'main_screen',
        payment_provider: 'card',
      });
    },
    [currencyCode, placement],
  );

  const failed = useCallback(
    ({
      pack,
      type = 'subscription',
      error,
    }: {
      pack: CreditPackAdaptedEntity | SubscriptionPackAdaptEntity;
      type?: ViewerSubscription;
      error?: string;
    }) => {
      Analytic.purchaseFailed({
        item_id: pack.id,
        currency: currencyCode,
        price: pack.price,
        type: type,
        placement: placement ?? 'main_screen',
        payment_provider: 'card',
        error_description: error || '',
      });
    },
    [currencyCode, placement],
  );

  const startCheckout = useCallback(
    ({
      pack,
      type = 'subscription',
    }: {
      pack: CreditPackAdaptedEntity | SubscriptionPackAdaptEntity;
      type?: ViewerSubscription;
    }) => {
      Analytic.addToCart({
        item_id: pack.id,
        currency: currencyCode,
        price: pack.price,
        type: type,
        placement: placement ?? 'main_screen',
      });
    },
    [placement, currencyCode],
  );

  return {
    success,
    failed,
    startCheckout,
  };
};
