import { UiTypography, UiTypographyProps } from '../ui-typography';
import { cn } from '@repo/common/utils/component';

export interface Props extends UiTypographyProps {}

export const UiError = (props: Props) => {
  const { className, ...restProps } = props;

  return <UiTypography variant="sm" className={cn('ui-error', className)} {...restProps} />;
};
