import type { ComponentPropsWithoutRef } from 'react';
import type { ReactSlot } from '@repo/common/types/helpers';
import { cn, renderSlot } from '@repo/common/utils/component';

export interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  leftSection: ReactSlot;
  rightSection: ReactSlot;
}

export const BasePaywallLayoutDialog = (props: Props) => {
  const { leftSection, rightSection, className, ...restProps } = props;

  return (
    <div className={cn('mod-flex mod-flex-col mod-h-full tablet:mod-flex-row', className)} {...restProps}>
      <div className="mod-basis-1/2 mod-grow mod-h-1/2 tablet:mod-w-[49.2%] tablet:mod-basis-[49.2%] tablet:mod-h-full">
        {renderSlot(leftSection)}
      </div>
      <div className="mod-basis-fit  mod-shrink tablet:mod-shrink-0 mod-h-fit tablet:mod-grow tablet:mod-w-[50.8%] tablet:mod-basis-[50.8%] flex flex-col tablet:mod-h-full">
        {renderSlot(rightSection)}
      </div>
    </div>
  );
};
